import React, { useEffect } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { Link } from "react-router-dom";

const RevolutionisingIndustrialDecarbonisation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header activelink="insights" />

      <main className="pb-lg-1 pb-sm-1">
        {/* <!--Blog Header--> */}
        <section className="hero-banner-insightdetailed">
          <div className="container  px-lg-4 py-lg-5 mx-auto">
            <div className="col-lg-10 mx-auto px-lg-5">
              <div className="row py-5">
                <div className="col-xxl-12">
                  <p>
                    <Link to="/insight" className="greyblog">
                      Blog
                    </Link>{" "}
                    /{" "}
                    <Link to="/insight" className="blackbackbtn">
                      {" "}
                      Back
                    </Link>
                  </p>
                  <h1 className="insight-detailed">
                  Revolutionising Industrial Decarbonisation: How Thermal Batteries Are Paving the Way for a Net-Zero Future
                  </h1>
                  <p className="insightdetailedby-line">
                    September 12, 2024 | 4 min read
                  </p>
                  <ul className="tool-btninsightdetailed">
                    <li>
                      <Link>Enterprise AI</Link>
                    </li>
                    <li>
                      <Link>Generative AI</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Blog Image--> */}
        <section>
          <img src="/img/insight/Revolutionisingbanner.png" alt="" width="100%" />
        </section>

        {/* <!--Blog Content--> */}
        <section className="mt-5">
          <div className="container px-lg-4">
            <div className="col-lg-10 mx-auto pb-5 blogcontent">
              <p>
              As the world accelerates its transition to renewable energy, the challenge of decarbonising heavy industries—like steel, cement, and chemical manufacturing—remains formidable. These sectors are traditionally reliant on continuous, high-temperature heat, often provided by fossil fuels. Enter thermal batteries, an innovative solution that promises to bridge the gap between intermittent renewable energy sources and the consistent energy demands of industrial processes.
              </p>

              <p className="head">
              What Are Thermal Batteries?
              </p>

              <p>
              Thermal batteries, such as those developed by Antora Energy, convert excess renewable electricity into stored heat using blocks of solid carbon. These blocks can be heated to over 1,500°C and retain this energy for extended periods. When needed, the stored heat can be released to power industrial processes or even be converted back into electricity using advanced thermophotovoltaic cells. This not only provides a steady energy supply but also ensures that industries can operate on 100% clean energy, even when the wind isn’t blowing or the sun isn’t shining.
              </p>

              <p className="head">
              Scaling Up for Impact
              </p>
              <p>
              Antora Energy, a pioneer in this field, has recently opened a large-scale manufacturing facility in San Jose, California, set to produce its modular thermal batteries. This facility represents a significant step forward in making this technology commercially viable on a global scale. The company has already secured significant funding, including a $150 million Series B round, and is poised to deliver some of the largest industrial decarbonisation projects in the world by 2025.
              </p>

              <p className="head">
              Why Thermal Batteries Matter
              </p>
              <p>
              The potential impact of thermal batteries on reducing industrial greenhouse gas emissions is immense. According to a report by the Renewable Thermal Collaborative, these batteries could provide a cost-competitive alternative to natural gas in many parts of the U.S., depending on factors like electricity pricing and policy support. Moreover, the modular nature of these batteries allows for easy integration into existing industrial systems, reducing the need for extensive infrastructure changes.
              </p>
              <p className="head">
              The Road Ahead
              </p>
              <p>
              While the technology is promising, there are still challenges to overcome, particularly in scaling up production and navigating regulatory frameworks that currently favour traditional energy sources. However, with continued investment and policy support, thermal batteries could play a crucial role in the global effort to achieve net-zero emissions by mid-century.
              </p>
              <p>
              As industries worldwide grapple with the dual pressures of reducing emissions and maintaining economic competitiveness, thermal batteries offer a compelling solution that aligns with the broader goals of sustainable development. By converting renewable energy into a reliable heat source, they not only reduce carbon footprints but also help stabilize energy costs—paving the way for a greener, more resilient industrial future.
              </p>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default RevolutionisingIndustrialDecarbonisation;
