import React from 'react';
import Slider from 'react-slick';
import ntpcLogo from '../assets/images/clients/ntpc.png';
import nhpclogo from '../assets/images/clients/nhpc.png';
import ntpcmineslogo from '../assets/images/clients/ntpc-mines.png';
import petroleumlogo from '../assets/images/clients/petroleum-ministry.png';
import reliancelogo from '../assets/images/clients/reliance.png';
import nvvnlogo from '../assets/images/clients/nvvn-solar.png';
import adityaBirlaLogo from '../assets/images/clients/abg-group.png';
import jindallogo from '../assets/images/clients/jindal.png';
import Indianrailwayslogo from '../assets/images/clients/Indianrail.png';

import '../assets/css/ClientsCarousel.css'; // Custom CSS for logo styling

const ClientsCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        nextArrow: false,
        prevArrow: false,
         
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="container clients-carousel-section">
            <div className="clients-container">
                {/* Left side heading */}
                <div className="clients-heading">
                    <h4>Trusted by Industry Leaders</h4>
                </div>

                {/* Right side carousel */}
                <div className="clients-carousel">
                 
                        <div className="client-logo">
                            <img src={ntpcLogo} alt="NTPC" />
                        </div>
                        <div className="client-logo">
                            <img src={nhpclogo} alt="NHPC" />
                        </div>

                        <div className="client-logo">
                            <img src={adityaBirlaLogo} alt="Aditya Birla Group" />
                        </div>
                        <div className="client-logo">
                            <img src={jindallogo} alt="Jindal Power" />
                        </div>

                        <div className="client-logo">
                            <img src={Indianrailwayslogo} alt="Indian Railways" />
                        </div>
                        
                        <div className="client-logo">
                            <img src={petroleumlogo} alt="Petroleum Ministry" />
                        </div>
                        <div className="client-logo">
                            <img src={reliancelogo} alt="Reliance" />
                        </div>
                        
                       
                   
                </div>
            </div>
        </section>
    );
};

export default ClientsCarousel;
