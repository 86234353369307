import { apiRequest } from "./apiRequest";

export const uploadFile = async (file,uploadFor,requestUrl) => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append('File', file); // 'file' should match the key expected by your API
      formData.append('UploadFor', "Resume");
      formData.append('RequestUrl', "https://Acompworldapi.acolabz.com/");
      // Make the API call using axios
     const headers = {
        'Content-Type': 'multipart/form-data', // Important to set the content type for file uploads
        'Authorization': localStorage.getItem('token'), // Include any necessary authorization headers
      }
      const response = await apiRequest({url : "/uploads", method: 'post', body:formData, header: headers});
  
      // Handle the response as needed
      console.log('Upload successful', response.data);
      return response.data;
    } catch (error) {
      // Handle errors
      console.error('Error uploading file', error);
    }
  };