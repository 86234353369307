import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import HomeBannerSlider from "../component/HomeBannerSlider";
import ClientsCarousel from "../component/ClientsCarousel";
import $ from "jquery";
import CaseStudyCarousel from "./CaseStudyCarousel";
import PlantLIiveCarousel from "./PlantLIiveCarousel";
import CustomDevelopment from "../component/CustomDevelopment";
import TransformCarousel from "../component/TransformCarousel";
import HomeBlogSection from "../component/HomeBlogSection ";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".improve-customer-sectionaccordian").on("click", function () {
        // Close all sections
        $(".improve-customer-sectionaccordian").addClass("collapsed");
        $(".collapse").removeClass("show");

        // Open the clicked section
        $(this).removeClass("collapsed");
        $(this).find(".collapse").addClass("show");

        // Print the accordion index which is open
        var index = $(this).index();

        // Check which accordion is open
        if (index === 0) {
          $("#accordianContent1").addClass("show").fadeIn(1000);
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").removeClass("show");
        } else if (index === 1) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").addClass("show").fadeIn(1000);
          $("#accordianContent3").removeClass("show");
        } else if (index === 2) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").addClass("show").fadeIn(1000);
        }
      });
    });
  }, []); // Empty dependency array ensures this runs once after the initial render

  return (
    <div>
      {/* Include the Header component */}
      <Header />

      {/* <!--Home Banner--> */}
      <HomeBannerSlider />

      {/* <!--Clients--> */}
      <ClientsCarousel />

      {/* <!--Solutions--> */}
      <section className="pb-lg-5 fade-in-element bottom ">
        <div className="container">
          <div className="row py-md-4 py-2">
            <div className="col-lg-6 py-lg-5 py-2">
              <h2 className="display-4 fw-bold mb-4">
                Transformative Digital Solutions
              </h2>
              <p className="fs-5 mb-4 fw-normal lh-2 Transformative-sectionp">
                Acompworld can help you solve your most pressing challenges and
                make better business decisions.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid g-0">
          <div className="row g-0">
            {[
              {
                title: "Plant Live",
                description: "A real-time monitoring solution offering dashboards, KPI tracking, and detailed reports by integrating plant systems and business applications.",
                colorClass: "red",
                image: "/img/home/plantlivetds.svg",
                imgWidth: "25px",
              },
              {
                title: "Epsylon",
                description: "An ML-powered system for early detection of equipment failures, minimizing downtime and preventing operational losses.",
                colorClass: "dark",
                image: "/img/home/epsylontds.svg",
                imgWidth: "25px",
              },
              {
                title: "Lumyn",
                description:
                  "A secure generative AI framework for creating knowledge assistants, conversational agents, and task automation tools.",
                colorClass: "dark",
                image: "/img/home/lumyntds.svg",
                imgWidth: "25px",
              },
              {
                title: "Uniteam",
                description: "An intuitive AI-driven platform for task management, employee engagement, and streamlined team collaboration.",
                colorClass: "dark",
                image: "/img/home/uniteamtds.svg",
                imgWidth: "25px",
              },
            ].map((solution, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className={`solution-card ${solution.colorClass} `}>
                  <div className="solution-card-inner">
                    <img
                      src={solution.image}
                      alt=""
                      className="mb-2"
                    
                    />
                    <h5>{solution.title}</h5>
                    <p>{solution.description}</p>
                  </div>
                  <span className="arrow">&rarr;</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <CustomDevelopment />
      <TransformCarousel />


      <section className="pb-lg-5 fade-in-element bottom known-more-section ">
        <div className="container">
          <div className="row py-md-4 py-2">
            <div className="col-lg-7 py-lg-5 py-2">
            <h2 className="display-4 fw-bold mb-4">
              {/* <p className="fs-4 mb-4 fw-normal lh-2 Transformative-sectionp">
              Our cross-disciplinary team combines strategy, branding, UX design, and technology for swift, impactful results. Working as one team with our clients, we merge human creativity with AI-driven efficiency to consistently exceed expectations.
              </p> */}
              Ready to Transform Your Business with Cutting-Edge Technology?
            </h2>

              <Link to="/contact"
              className="fs-6 btn btn-lg bg-danger text-white">
               Let's Get Started
              </Link>

            </div>
          </div>
        </div>
        
      </section>
      <HomeBlogSection />
      <Footer />
    </div>
  );
};

export default Home;
