import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";

const IotCarousel = () => {
  const carouselRef = useRef(null);

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.prev(); // Call the `prev` method
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next(); // Call the `next` method
    }
  };

  return (
    <div className="inner-carusel-container">
      <OwlCarousel ref={carouselRef} className="owl-theme" {...options}>
        <div className="item">
          <div className="case-study-img">
            <img
              src="/img/solution/sub-banner.png"
              alt=""
              width="100%"
              className="fade-in-element left"
            />
            <div className="case-study-content fade-in-element right">
              <div className="content-casestudy">
                <h4>
                  <b>Client: </b> NTPC Ltd, India
                </h4>
                <p>
                  Implemented drone-assisted coal stock measurement, leveraging
                  IoT-enabled 3D imaging to provide fast, safe, and accurate
                  stockpile volume calculations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="case-study-img">
            <img
              src="/img/solution/sub-banner.png"
              alt=""
              width="100%"
              className="fade-in-element left"
            />
            <div className="case-study-content fade-in-element right">
              <div className="content-casestudy">
                <h4>
                  <b>Client: </b> Mid-Sized Engineering Company, India
                </h4>
                <p>
                  Deployed a mobile IoT solution for workforce tracking,
                  ensuring precise time and location monitoring to improve
                  efficiency and reduce operational overheads.
                </p>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
      {/* Custom navigation buttons */}
      <button className="custom-prev-button" onClick={handlePrevClick}>
        <i className="fa-solid fa-arrow-left-long"></i>
      </button>
      <button className="custom-next-button" onClick={handleNextClick}>
        <i className="fa-solid fa-arrow-right-long"></i>
      </button>
    </div>
  );
};

export default IotCarousel;
