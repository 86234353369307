import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery"; // Make sure to import jQuery
import PlantLIiveCarousel from "./PlantLIiveCarousel";
import EpsylonCarousel from "./EpsylonCarousel";

const SolutionsEpsylon = () => {
    const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".improve-customer-sectionaccordian").on("click", function () {
        // Close all sections
        $(".improve-customer-sectionaccordian").addClass("collapsed");
        $(".collapse").removeClass("show");

        // Open the clicked section
        $(this).removeClass("collapsed");
        $(this).find(".collapse").addClass("show");

        // Print the accordion index which is open
        var index = $(this).index();

        // Check which accordion is open
        if (index === 0) {
          $("#accordianContent1").addClass("show").fadeIn(1000);
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").removeClass("show");
          $("#accordianContent4").removeClass("show");
          $("#accordianContent5").removeClass("show");
        } else if (index === 1) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").addClass("show").fadeIn(1000);
          $("#accordianContent3").removeClass("show");
          $("#accordianContent4").removeClass("show");
          $("#accordianContent5").removeClass("show");
        } else if (index === 2) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").addClass("show").fadeIn(1000);
          $("#accordianContent4").removeClass("show");
          $("#accordianContent5").removeClass("show");
        }
        else if (index === 3) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").removeClass("show");
          $("#accordianContent4").addClass("show").fadeIn(1000);
          $("#accordianContent5").removeClass("show");
        }else if (index === 4) {
          $("#accordianContent1").removeClass("show");
          $("#accordianContent2").removeClass("show");
          $("#accordianContent3").removeClass("show");
          $("#accordianContent4").removeClass("show");
          $("#accordianContent5").addClass("show").fadeIn(1000);

        }
      });
    });
  }, []); // Empty dependency array ensures this runs once after the initial render

const handleDemoClick = () => {
    navigate('/contact', {
        state: {
          demoNeededFor: "AI Chatbots",  // Replace this with your actual data
        },
      });
}
    

  return (
    <>
      <Header activelink="solutions" />

      <main className="pb-lg-1 pb-sm-1">
        {/* <!--Page Banner--> */}
        <section className="hero-bannercareer">
          <div className="container py-lg-5">
            <div className="col-lg-12 mx-auto">
              <div className="row py-5">
                <div className="col-xxl-7 col-lg-8 fade-in-element left">
                  <p className="small__by_text">
                    {" "}
                    <Link to="/solutions-plant-live"> Solutions /</Link>{" "}
                    <span>
                      <Link to="/solutions-plant-live">Epsylon</Link>
                    </span>
                  </p>

                  <h1 className="Careers-herobannertext">Epsylon</h1>
                  <p className="lead-careertext">
                  Stay Ahead of Equipment Failures and Reduce Downtime

                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                    <span  onClick={()=>handleDemoClick()}
                    className="btn btn-careerseeopen">
                      Request a demo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="img/home/circle-drw.png"
            alt=""
            width="100%"
            className="circle-elipsse"
          />
        </section>

        {/* <!--Para--> */}
        <section className="solution-section">
          <div className=" py-md-5 py-3 pt-sm-2 px-sm-2 container">
            <div className="col-lg-12 mx-auto">
              <div className="ai-chatsolution py-md-5 py-2 fade-in-element bottom">
              
                <p>
                Epsylon is Acompworld’s advanced machine learning-based early warning system designed to revolutionize industrial maintenance. By leveraging deep learning models trained on your operational data, Epsylon detects anomalies far earlier than traditional systems, helping you avoid costly downtimes and production losses.

                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Chat Section--> */}
        <section>
          <div className="container pt-md-5">
            <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-sm-2">
              <div className="col-lg-10 ">
                <h2 className="career-headingwhyjoin">
                Key Features
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto">
              <div className="row">
                <div className="col-lg-6 fade-in-element left">
                  <div className="col-lg-10">
                    <div className="reduced">
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian"
                        data-bs-toggle="collapse"
                        href="#collapseExampleim"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExampleim"
                      >
                        <h4 className="">Deep Learning Models</h4>
                        <div className="collapse show " id="collapseExampleim">
                          <p>
                          Trained on historical and real-time data to deliver unparalleled accuracy in anomaly detection.


                          </p>
                        </div>
                      </div>
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <h4 className="">Multi-Source Data Integration</h4>
                        <div className="collapse" id="collapseExample">
                          <p>
                          Consolidates data from sensors, equipment logs, and other operational systems.

                          </p>
                        </div>
                      </div>

                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample1"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample1"
                      >
                        <h4>
                        User-Friendly Interface
                        </h4>
                        <div className="collapse" id="collapseExample1">
                          <p>
                          Intuitive dashboards for clear visualization of equipment health and predictive alerts.


                          </p>
                        </div>
                      </div>
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample145"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample145"
                      >
                        <h4>Actionable Insights</h4>
                        <div className="collapse" id="collapseExample145">
                          <p>
                          Provides detailed recommendations to address flagged issues, ensuring smooth operations.

                          </p>
                        </div>
                      </div>
                      <div
                        className="p-md-4 p-2 mt-lg-2 improve-customer-sectionaccordian collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseExample178"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample178"
                      >
                        <h4>Scalable Deployment</h4>
                        <div className="collapse" id="collapseExample178">
                          <p>
                          Suitable for single-site or multi-plant industrial setups.

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div
                    className="position-relative collapse show fade-in-element right"
                    id="accordianContent1"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/chat-section.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Hello! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I'm trying to find my order status but I'm lost.
                      </div>
                      <div className="box-3">
                        No worries! Just give me your order number, and I'll
                        fetch the status for you right away.
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent2"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/girl-ai-chat.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1 box-whcontrol-1">
                        Hi there! I see you've added a cocktail dress to your
                        cart. Would you like to see matching accessories?
                      </div>

                      <div className="box-2 box-whcontrol-2">
                        Sure, why not.
                      </div>
                      <div className="box-3">
                        Great! Our pearl necklace and clutch bag would complete
                        the look. Would you like to add them to your cart?
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent3"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/oldmen-chatai.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Welcome back! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I want to change my subscription plan.
                      </div>
                      <div className="box-3">
                        Of course! You can switch to our Premium Plan at
                        $15/month or our Enterprise Plan at $25/month. Which one
                        suits you?
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent4"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/oldmen-chatai.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Welcome back! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I want to change my subscription plan.
                      </div>
                      <div className="box-3">
                        Of course! You can switch to our Premium Plan at
                        $15/month or our Enterprise Plan at $25/month. Which one
                        suits you?
                      </div>
                    </div>
                  </div>

                  <div
                    className="position-relative collapse fade-in-element right"
                    id="accordianContent5"
                  >
                    <div className="text-end">
                      <img
                        src="/img/solution/oldmen-chatai.png"
                        alt=""
                        width="60%"
                      />
                    </div>

                    <div className="chat-boxs">
                      <div className="box-1">
                        Welcome back! How can I assist you today?
                      </div>

                      <div className="box-2">
                        I want to change my subscription plan.
                      </div>
                      <div className="box-3">
                        Of course! You can switch to our Premium Plan at
                        $15/month or our Enterprise Plan at $25/month. Which one
                        suits you?
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Chatbots apart--> */}
        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-2">
              <div className="col-lg-6">
                <h2 className="career-headingwhyjoin">
                Why Choose Epsylon?{" "}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="">
              <div className="row">
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element left">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-sloution001.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                      Proactive Maintenance
                      </h3>
                      <p className="industries-retailerpara">
                      Identify potential issues before they escalate, reducing unplanned downtime and repair costs.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element right">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution002.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                      Enhanced Reliability
                      </h3>
                      <p className="industries-paratext">
                      Keep your equipment running at peak performance with precise and timely alerts.


                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element left">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution003.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                      Cost Efficiency
                      </h3>
                      <p className="industries-paratext">
                      Minimize production disruptions, optimize maintenance schedules, and improve asset longevity.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element right">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-solution004.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                      Customizable Models
                      </h3>
                      <p className="industries-retailerpara">
                      Tailored to your specific industrial equipment and operational parameters.

                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element right">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-solution004.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                      Scalable and Flexible
                      </h3>
                      <p className="industries-retailerpara">
                      Suitable for individual plants or multi-site industrial operations.

                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!--Pathbreaking Pilots--> */}
        <section>
          <div className="container">
            <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
              <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                Pathbreaking Pilots
              </h2>
            </div>
          </div>
        </section>

        <section className="">
       <EpsylonCarousel />
        </section>
        <div className="my-lg-5 py-lg-5"></div>
      </main>

      {/* <!-- Footer match --> */}
      <section className="hero-bannercareer border-bottomfooter">
        <div className="container py-4">
          <div className="col-lg-12 mx-auto">
            <div className="row py-md-5 py-2">
              <div className="col-lg-12 fade-in-element left">
                <h1 className="chat-llm">
                Unlock the Future of Maintenance with Epsylon

                </h1>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                <span  onClick={()=>handleDemoClick()} className="btn btn-consulation">
                Request a Demo
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SolutionsEpsylon;

