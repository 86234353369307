import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom for navigation
import banner1 from "../assets/images/energy-banner.webp";
import banner2 from "../assets/images/manufacturing-banner.webp";

const HomeBannerSlider = () => {
  return (
    <section className="homebanner">
      <div>
        <Carousel indicators={false}>
          {/* Slide 1 */}
          <Carousel.Item>
            <div className="carousel-item-container ">
              <img
                src="/img/home/home-banner2.png"
                alt="Energy Banner"
                className="bannerImage"
              />
              {/* <div className="overlay"></div> */}
              <div className="container  bannertext pt-5">
                <div className="row flex-lg-row align-items-center py-2">
                  <div className="col-md-6 left-content">
                    <h1 className="display-3 fw-bold lh-1 mb-4 mt-4 text-white text-left">
                      Empowering Energy Operations with Intelligent Solutions
                    </h1>
                    <p className="mb-3 fs-8 text-white text-left">
                      Harness the power of AI, IoT, and real-time analytics to
                      optimize plant performance, ensure safety compliance, and
                      drive sustainable energy outcomes.
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-start">
                      <Link
                        to="/contact"
                        className="btn  fs-6 btn-lg me-md-2 requestdemo new-homepagebtn"
                      >
                        Explore More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          {/* Slide 2 */}
          <Carousel.Item>
            <div className="carousel-item-container">
              <img
                src="/img/home/home-banner1.png"
                alt="Manufacturing Banner"
                className="bannerImage"
              />
              {/* <div className="overlay"></div> */}
              <div className="container bannertext pt-5 ">
                <div className="row flex-lg-row align-items-center py-2">
                  <div className="col-md-6 ">
                    <h1 className="display-3 fw-bold lh-1 mb-4 mt-4 text-white text-left">
                      Accelerating Manufacturing with Smart Technologies
                    </h1>
                    <p className="mb-3 text-white text-left">
                      Streamline workflows, prevent equipment downtime, and
                      boost efficiency with advanced predictive analytics and
                      automation tailored for the manufacturing industry.
                    </p>
                    <div className="d-grid gap-2 d-md-flex justify-content-start">
                      <Link
                        to="/contact"
                        className="btn fs-6 btn-lg me-md-2 requestdemo new-homepagebtn"
                      >
                        Explore More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default HomeBannerSlider;
