import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid px-lg-5 bg-light">
      <footer className="row py-5 mx-3 footer-container">
        {/* Left Section */}
        <div className="col-md-9 mb-4">
          <h1 className="mb-4">Let's Talk</h1>
          <p className="mb-3">
            <a
              href="mailto:info@acompworld.com"
              className="text-dark text-decoration-none"
            >
              info@acompworld.com
            </a>
          </p>
          <ul className="list-unstyled d-flex gap-4 social-icons">
            <li>
              <Link to="#" className="social-icon">
                <img src="/img/home/fb-logo.svg" alt="Facebook" />
              </Link>
            </li>
            <li>
              <Link to="#" className="social-icon">
                <img src="/img/home/linkedin-logo.svg" alt="LinkedIn" />
              </Link>
            </li>
            <li>
              <Link to="#" className="social-icon">
                <img
                  src="/img/home/x-logo.svg"
                  alt="Twitter/X"
                  style={{ width: "18px", height: "18px" }}
                />
              </Link>
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div className="col-md-3 row mt-3">
          <div className="col-6">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/solutions-plant-live" className="nav-link p-0">
                  Solutions
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/solutions-epsylon" className="nav-link p-0">
                  Company
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="/solutions-lumyn" className="nav-link p-0">
                  Contact
                </Link>
              </li>
              {/* <li className="nav-item mb-2">
                <Link to="/solutions-uniteam" className="nav-link p-0">
                  Uniteam
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-6">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="/services-data-analytics" className="nav-link p-0">
                  Services
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/services-erp-workflow-automation"
                  className="nav-link p-0"
                >
                  Insights
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="row">
          <div className="col-md-9">
            <ul className="list-inline btf-section">
              <li className="list-inline-item">
                <Link to="/privacy-policy" className="text-muted pe-4">
                  Privacy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/terms" className="text-muted pe-4">
                  Terms
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/sitemap" className="text-muted ">
                  Sitemap
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <p className="footer-copy">© 2023 acompworld.com</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
