import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';

const ServicesDataAnalyticsCarousel = () => {
    const carouselRef = useRef(null); // Ref for accessing the carousel instance

    const options = {
        loop: true,
        margin: 10,
        nav: false, // Disable built-in navigation
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <div className="inner-carusel-container">
            {/* Attach ref to OwlCarousel */}
            <OwlCarousel
                ref={carouselRef}
                className="owl-theme"
                {...options}
            >
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                               
                                <h4><b>Client: </b>NTPC Ltd</h4>
                                <p>
                                Our real-time dashboards enabled clients to monitor key operational metrics such as maintenance schedules, production goals, and environmental compliance, driving improved efficiency and faster decision-making.
                                 </p>                               
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                    <img
                            src="/img/industries-retailer/industries-banner-mid.png"
                            alt="Jindal Power Ltd"
                            width="100%"
                            className="fade-in-element left fade-in-left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                           
                            <h4><b>Client: </b>Jindal Power Ltd</h4>
                                <p>
                                Revolutionizing coal production monitoring with a real-time dashboard and mobile app, offering rich analytics for operational efficiency and safety.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
               
            </OwlCarousel>
            {/* Custom navigation buttons */}
            <button className="custom-prev-button" onClick={handlePrevClick}>
                <i className="fa-solid fa-arrow-left-long"></i>
            </button>
            <button className="custom-next-button" onClick={handleNextClick}>
                <i className="fa-solid fa-arrow-right-long"></i>
            </button>
        </div>
    );
};

export default ServicesDataAnalyticsCarousel;
