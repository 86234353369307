import React, { useEffect,useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Header({ activelink }) {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const elements = document.querySelectorAll('.fade-in-element');

        const handleScroll = () => {
            elements.forEach((element) => {
                const elementTop = element.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;


                if (elementTop < windowHeight * 0.8) {
                    if (element.classList.contains('left')) {
                        element.classList.add('fade-in-left');
                    } else if (element.classList.contains('right')) {
                        element.classList.add('fade-in-right');
                    } else if (element.classList.contains('bottom')) {
                        element.classList.add('fade-in-bottom');
                    } else {
                        element.classList.add('visible');
                    }
                }
            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Initial check in case elements are already visible on load
        handleScroll();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate()
    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Generic",  // Replace this with your actual data
            },
          });
    }


    return (
        <>
            <header   className={`fixed-top ${scrolled ? 'navbar-scrolled' : ''}`}>
                
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg nav-height" aria-label="Eleventh navbar example">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img src="/img/Logo-White.svg" alt="logo" className="logo" />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarsExample09"
                                aria-controls="navbarsExample09"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fa-solid fa-chart-bar text-white"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarsExample09">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown">
                                        <Link
                                            className={`${activelink === 'solutions' ? 'nav-link dropdown-toggle text-white px-4 active' : 'nav-link dropdown-toggle text-white px-4'}`}
                                            to="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >Solutions</Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/solutions-plant-live" className="dropdown-item">Plant Live</Link>
                                            </li>
                                            <li>
                                                <Link to="/solutions-epsylon" className="dropdown-item">Epsylon</Link>
                                            </li>
                                            <li>
                                                <Link to="/solutions-lumyn" className="dropdown-item">Lumyn</Link>
                                            </li>
                                            <li>
                                                <Link to="/solutions-uniteam" className="dropdown-item">Uniteam</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link
                                            className={`${activelink === 'services' ? 'nav-link dropdown-toggle text-white px-4 active' : 'nav-link dropdown-toggle text-white px-4'}`}
                                            to="#"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >Services</Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/services-data-analytics" className="dropdown-item">Data Analytics, BI & Dashboards</Link>
                                            </li>
                                            <li>
                                                <Link to="/services-erp-workflow-automation" className="dropdown-item">ERP Systems & Workflow Automation</Link>
                                            </li>
                                            <li>
                                                <Link to="/services-web-intranet-cms" className="dropdown-item">Web Portals, Intranets & CMS</Link>
                                            </li>
                                            <li>
                                                <Link to="/services-mobile-apps" className="dropdown-item">Mobile Applications</Link>
                                            </li>
                                            <li>
                                                <Link to="/services-app-modernization-migration" className="dropdown-item">Legacy Application Modernization</Link>
                                            </li>
                                            <li>
                                                <Link to="/services-iot-solutions" className="dropdown-item">IoT (Internet of Things) Solutions</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/company" className={`${activelink === 'company' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Company</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/insight" className={`${activelink === 'insights' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>Insights</Link>
                                    </li>
                                </ul>
                                <div className="d-lg-flex col-lg-3 justify-content-lg-end">
                                    <ul className="navbar-nav mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <Link to="/contact" className={`${activelink === 'contact' ? 'nav-link px-4 text-white active' : 'nav-link px-4 text-white'}`}>
                                             Contact
                                            </Link>
                                        </li>
                                        <li className="nav-item pe-0 nav-item-btn-width">
                                            <button onClick={()=>handleDemoClick()}
                                            style={{cursor:"pointer"}}
                                             className={`${activelink === 'contact' ? 'nav-link px-4 text-white active btn-navtopc '  : 'nav-link px-4 text-white btn-navtopc '}`}>
                                                Schedule a Call
                                             </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};