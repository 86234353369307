import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link  } from 'react-router-dom';

const Company = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])    


  return (
    <>
        <Header activelink='company'/>

        <main>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5 ">
                    <div className="col-xxl-12 mx-auto pb-md-4">
                        <div className="row py-5 fade-in-element left">
                            <div className="col-xxl-7 col-lg-10">
                                <h1 className="Careers-herobannertext">Your Partner in AI Innovation</h1>
                                <p className="lead-careertext">Cultivating a future where AI and human ingenuity drive growth.</p>
                            </div>
                        </div> 
                    </div>
                </div>                
                <img src="/img/company/banner-companyshort.png" alt="" className="circle-elipsse-retailer earth"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light py-5">
                <div className="px-lg-5 px-sm-2 my-sm-2 container-fluid">
                    <div className="col-lg-11 mx-auto">                      
                        <p className="lead-careerbylinecompay mb-md-4 mb-3 fade-in-bottom">
                        At Acompworld, our mission is to empower businesses with intelligent, scalable, and transformative technology. We aim to deliver exceptional value by combining technical expertise with a deep understanding of our clients’ industries.
                        </p>                        
                    </div>
                </div>
            </section>

            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Our Story</h3>  
                            <p>Founded in 2012 in Singrauli, India’s industrial hub for power generation and coal mining, Acompworld has grown into a trusted name in IT solutions. With offices in Noida and Bhopal, and a team of 40 dedicated professionals, we partner with leading organizations like NTPC, Reliance, AV Birla Group, and Indian Railways to deliver innovative technology solutions.</p>
                            <p>
                               Our roots in energy and manufacturing sectors have shaped our expertise in solving industry-specific challenges through cutting-edge technology, from AI-driven solutions to IoT innovations.

                            </p>
                        </div>
                    </div>
                </div>
            </section>  

            <section>
          <div className="container">
            <div className="col-lg-12 mx-auto my-lg-5 pt-sm-2 my-2">
              <div className="col-lg-6">
                <h2 className="career-headingwhyjoin">
                Why Choose Us? {" "}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="">
              <div className="row">
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element left">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-sloution001.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                      Proven Expertise
                      </h3>
                      <p className="industries-retailerpara">
                      With over a decade of experience and a track record of successful projects, we deliver solutions that work.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element right">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution002.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                      Innovative Technology
                      </h3>
                      <p className="industries-paratext">
                      Leveraging AI, machine learning, and IoT, we stay at the forefront of tech innovation.

                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 p-0">
                  <div className="card card-customindustries mt-lg-0 mt-sm-4 fade-in-element left">
                    <div className="card-body px-lg-5 px-sm-2 py-4">
                      <img
                        src="/img/solution/card-solution003.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-textheading">
                      Client-Centric Approach
                      </h3>
                      <p className="industries-paratext">
                      We prioritize understanding our clients' unique challenges and goals to deliver customized solutions.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                  <div className="card card-customindustriesdack fade-in-element right">
                    <div className="card-body px-lg-5 py-4">
                      <img
                        src="/img/solution/card-solution004.png"
                        alt=""
                        className="my-3"
                      />
                      <h3 className="industries-retailercardheading">
                      Trusted by Industry Leaders
                      </h3>
                      <p className="industries-retailerpara">
                      Our partnerships with top organizations stand as a testament to our reliability and impact.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

            

            {/* <!--Led by Experience--> */}
            <section className="py-2">
                <div className="px-lg-4 py-lg-5 mt-lg-5 pt-sm-2 px-sm-2 my-sm-2 container">
                    <div className="col-lg-12 mx-auto pt-lg-5">                      
                        <div className="row mb-4">
                            <div className='col-lg-12'>
                                <h2 className="career-headingwhyjoin mb-lg-4 font-sizecompanycontrol">Meet Our Leader</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                
                                <p className="passionline mb-md-5 mb-3">
                                Manish Shrivastava brings a wealth of experience in software development and consulting. A visionary entrepreneur, he has led Acompworld with a passion for innovation and a commitment to excellence.

                                </p>
                                
                            </div>
                            <div className="col-lg-1">
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                        
                                    <div className="col-lg-4 col-md-6 fade-in-element right  mb-4">
                                        <div className="image-meet-team">
                                            <img src="/img/company/manish.png" alt=""/>
                                            <div className="caption"><span>Manish Shrivastava </span><a href="https://www.linkedin.com/in/manishshrivast/" target='blank'> <img src="/img/linkdin-company.png" alt="" className="imgling-din"/> </a></div>
                                            <div className="tittle pb-md-3 pb-2">Founder & CEO
                                            </div>                                                
                                        </div>
                                    </div>    
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>    
 {/* <!-- Footer match --> */}
 <section className="hero-bannercareer  border-bottomfooter">
                <div className="container pb-5">
                    <div className="col-lg-10 py-lg-5 mx-auto">
                        <div className="row pt-lg-5">
                            <div className="col-lg-12 fade-in-element left">
                                <h1 className="chat-llm">
                               
                                Let’s Build the Future Together with Smart Technology Solutions!
                                </h1>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                                    <Link to="/contact" className="btn btn-consulation">Contact Us Today</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        <Footer />
    </>
  )
}

export default Company
