import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link,useNavigate } from 'react-router-dom'
import ServicesDataAnalyticsCarousel from './ServicesDataAnalyticsCarousel'
import ErpCarousel from './ErpCarousel'
import WebPortalCarousel from './WebPortalCarousel'

const ServicesMobileApps = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Retail",  // Replace this with your actual data
            },
          });
    }

  return (
    <>
        <Header activelink='industries'/>

        <main style={{backgroundColor:'#fff'}}>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5">
                    <div className="col-xxl-12 mx-auto">
                        <div className="row py-5">
                            <div className="col-xxl-8 col-lg-9 fade-in-element left">
                                <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-retail">Retail</Link></span></p>
                                <h1 className="Careers-herobannertext">Mobile Applications</h1>
                                <p className="lead-careertext">Power Your Business with High-Performance Apps


                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <img src="/img/industries-retailer/object.png" alt="" className="circle-elipsse-retailer"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light">
                <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
                    <div className="col-lg-11 mx-auto">                       
                        <div className="">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                            Acompworld specializes in designing and developing innovative mobile applications for iOS and Android that elevate user experience and streamline business processes. Whether it’s engaging customers, empowering employees, or managing operations, our mobile solutions deliver exceptional performance and functionality.

                        </p>
                        </div>                        
                    </div>
                </div>
            </section>

            {/* <!--AI Solutions for Retail--> */}
            <section>
                <div className=" container-fluid px-lg-5 pt-md-2">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">Why Choose Our Mobile App Services?

                        </h2>                      
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Custom-Built Apps</h3>
                                    <p className="industries-retailerpara">
                                    Tailored to meet your business needs and goals.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer2.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Seamless User Experience</h3>
                                    <p className="industries-paratext">
                                    Intuitive design and smooth functionality for maximum user satisfaction.

                                         </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer3.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Cross-Platform Compatibility</h3>
                                    <p className="industries-paratext">
                                    Apps that work seamlessly across iOS and Android devices.

                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer4.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Scalable Solutions</h3>
                                    <p className="industries-retailerpara">
                                    Designed to grow with your business and evolving requirements.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer5.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Robust Security</h3>
                                    <p className="industries-retailerpara">
                                    Built with enterprise-grade security features to protect user and business data.

                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            {/* <!--Driving Tangible Growth in the Retail Space--> */}
            <section className="epb-lg-5 mb-lg-5 pt-lg-5">
                <div className="container-fluid px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin">Our Key Offerings</h2>                     
                    </div>
                </div>
                <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                <h5>Enterprise Apps</h5>
                                <p>  Enhance productivity and collaboration with apps designed for internal business operations.

                                </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Customer Engagement Apps</h5>
                                    <p>  Create intuitive, user-friendly apps to connect with and retain customers.

                                    </p>       
                                </div> 
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>IoT-Enabled Mobile Solutions</h5>
                                    <p>Manage and monitor smart devices directly through mobile applications.
                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>E-Commerce Apps</h5>
                                    <p> Deliver seamless shopping experiences with integrated payment gateways and order management.
                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Maintenance & Support</h5>
                                    <p> Ongoing app updates and support to ensure optimal performance.
                                    </p>       
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </section>

            {/* <!--Trust Acompworld--> */}
            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Who Benefits from These Solutions?
                            </h3> 
                            <p>Our mobile applications cater to businesses across various industries looking to:


                            </p>
                            <p>
                                <b className="text-white">Engage customers and improve service delivery.



                            </b> </p>
                            <p>
                                <b className="text-white">Streamline field operations and workforce management.
                                </b> 
                            </p>
                            <p>
                                <b className="text-white">Leverage mobile technologies to enhance productivity and collaboration.
                                </b> 
                            </p>
                        </div>
                    </div>
                </div>
            </section>           
        </main>
        <section>
                <div className="container">
                    <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
                    <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                    Pathbreaking Pilots
                    </h2>
                    </div>
                    
                </div>
                <WebPortalCarousel />
            </section>
        
        {/* <!-- Footer match --> */}
        <section className="hero-bannercareer border-bottomfooter fade-in-element">
            <div className="container py-4">
                <div className="col-lg-12 mx-auto">
                    <div className="row py-md-5 py-2">
                        <div className="col-lg-12">
                            <h1 className="chat-llm">
                            Stay Connected with Mobile Solutions that Deliver


                            </h1>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                            <span onClick={handleDemoClick} className="btn btn-consulation">Let’s Build Your App</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <Footer />
    </>
  )
}

export default ServicesMobileApps


