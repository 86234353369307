import React, { useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

const WebPortalCarousel = () => {
    const carouselRef = useRef(null); // Ref for accessing the carousel instance

    const options = {
        loop: true,
        margin: 10,
        nav: false, // Disable built-in navigation
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    return (
        <div className="inner-carusel-container">
            <OwlCarousel
                ref={carouselRef}
                className="owl-theme"
                {...options}
            >
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                              
                                <h4><b>Client: </b>  NTPC Ltd, India</h4>
                                <p>
                                We developed a feature-rich intranet for NTPC across 40 locations, enabling seamless content updates in English and Hindi while improving collaboration and efficiency.
                                </p>                               
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                              
                                <h4><b>Client: </b>   Export Import Bank of India, India</h4>
                                <p>
                                Delivered an online recruitment system to automate and streamline the talent acquisition process, reducing time-to-hire by 30%.
                                </p>                               
                                
                            </div>
                        </div>
                    </div>
                </div>
              
            </OwlCarousel>
            {/* Custom navigation buttons */}
            <button className="custom-prev-button" onClick={handlePrevClick}>
                <i className="fa-solid fa-arrow-left-long"></i>
            </button>
            <button className="custom-next-button" onClick={handleNextClick}>
                <i className="fa-solid fa-arrow-right-long"></i>
            </button>
        </div>
    );
};

export default WebPortalCarousel;


