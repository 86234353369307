import React from "react";
import { Link } from "react-router-dom";

const HomeBlogSection = () => {
  const blogs = [
    {
      title:
        "Product as a Service (PaaS) Models: Transforming the Manufacturing Landscape",
      category: "Branding",
      date: "Mar 1, 2024",
      readingTime: "9 min read",
      image: "/img/insight/digitalthumb.png",
    },
    {
      title:
        "AI for Emissions Reduction and Environmental Compliance in Oil and Gas",
      category: "Branding",
      date: "Mar 1, 2024",
      readingTime: "9 min read",
      image: "/img/insight/digital.png",
    },
    {
      title: "Energy-as-a-Service (EaaS): A New Era in Sustainable Energy",
      category: "Branding",
      date: "Mar 1, 2024",
      readingTime: "9 min read",
      image: "/img/insight/Revolutionising.png",
    },
    {
      title:
        "AI's Impact on Engineering Jobs: Why Critical Thinking is the Key to Future Success",
      category: "Branding",
      date: "Mar 1, 2024",
      readingTime: "9 min read",
      image: "/img/insight/Hallucinationsthumbnail.png",
    },
  ];

  return (
    <section className="pb-lg-5 fade-in-element bottom home-blog-section text-white">
      <div className="container">
        <div className="row">
          <div className="row pt-md-4">
            <div className="col-lg-6  pt-5">
              <h2 className="display-4 fw-bold">Stay ahead of the AI curve</h2>
            </div>
          </div>

          {blogs.map((blog, index) => (
            <div
              className="blog-card-home-box col-12 col-md-6 col-lg-12 py-4"
              key={index}
            >
              <div className="d-flex flex-column flex-lg-row py-lg-5">
                <div className="blog-card-img col-lg-5 mb-3 mb-lg-0 pe-lg-5">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="img-fluid w-100 rounded-4"
                  />
                </div>
                <div className="blog-card-content col-lg-7 ms-lg-5 position-relative">
                  <p className="blog-category">{blog.category}</p>
                  <h3 className="fs-1">{blog.title}</h3>
                  <p className="blog-date" >
                    {blog.date} • {blog.readingTime}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="col-lg-12 text-center py-3">
          <Link
            to="/insight"
            className="d-flex justify-content-center align-items-center blog-home-view-more"
          >
            <p>
              View All Blogs <i className="fas fa-arrow-right ms-2"></i>
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeBlogSection;
