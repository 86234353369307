import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { uploadFile } from '../service/UploadService';
import { Spinner } from 'react-bootstrap';
import GeneralUtils from '../utils/GeneralUtils';
import Loader from './Loader';

const ApplyNowModal = ({ isOpen, onClose, onSubmit, jobDetail, loaders }) => {

  const [jobLocation, setJobLocation] = useState([]);
  useEffect(() => {
    if (jobDetail.jobLocation) {
      setJobLocation(jobDetail.jobLocation.split('/'));
    }
  }, [jobDetail.jobLocation])


  const [applyFormData, setApplyFormData] = useState({
    job_id: '',
    name: '',
    email: '',
    phone: '',
    position: jobDetail.position || '',
    resume: '',
    job_location: jobDetail.jobLocation || ''
  });

  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);

  const handleCloseModal = () => {
    onClose();
    setApplyFormData
      ({
        name: '',
        email: '',
        phone: '',
        position: '',
        resume: '',
        job_location: ''
      });
  }

  const [error, setError] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplyFormData({ ...applyFormData, [name]: value });
  };

  const handleFileChange = async (e) => {
    setLoader(true);
    const response = await uploadFile(e.target.files[0], "Resume", "https://Acompworldapi.acolabz.com/");
    setApplyFormData({ ...applyFormData, resume: response?.data.file_path });
    setLoader(false);
  };

  const handleSubmit = () => {

    if (applyFormData.phone && applyFormData.phone.length < 10) {
      setError(true);
      return;
    } else if (applyFormData.phone && applyFormData.phone.length > 15) {
      setError(true);
      return;
    }

    if (applyFormData.resume && !applyFormData.resume.includes('.pdf') && !applyFormData.resume.includes('.doc') && !applyFormData.resume.includes('.docx')) {
      setError(true);
      return;
    }
    //e.preventDefault();
    if (!applyFormData.resume || !applyFormData.name || !applyFormData.email
      || !applyFormData.phone || !applyFormData.position || !applyFormData.job_location) {
      setError(true);
      return;
    }
    else if (!GeneralUtils.validationForEmail(applyFormData.email)) {
      setError(true);
      return;
    }
    else {
      setLoader1(true);
      onSubmit(applyFormData);
      handleCloseModal();
      setLoader1(false);
      setError(false);

    }
    // Your form submission logic goes here

  };

  useEffect(() => {

    if (isOpen) {
      setError(false);
      /* set jobDetail values to apply form data */
      setApplyFormData({
        ...applyFormData,
        position: jobDetail.position,
        job_location: jobDetail.jobLocation
      });
    }
  }, [isOpen])

  return (
    <>
      <main>

        <Modal show={isOpen} onHide={handleCloseModal} id="modal-ApplyNow">

          <Modal.Header closeButton>
            <Modal.Title>Apply Now</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {loader1 ? <Loader /> :

              <Form className='careerform'>
                <Row>

                  <Col lg={6} >
                    <Form.Group controlId="formName">
                      <Form.Label>Full Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={applyFormData.name}
                        onChange={(e) => {
                          if (GeneralUtils.validationForName(e.target.value)) {
                            handleInputChange(e)
                          }
                        }
                        }
                      />
                      <div className="errorbox">
                        {error && !applyFormData.name && <p className="validation">**Full Name is Required</p>}
                      </div>

                    </Form.Group>

                  </Col>
                  <Col lg={6} >
                    <Form.Group controlId="formName">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={applyFormData.email}
                        onChange={(e) => {
                          handleInputChange(e)
                        }
                        }
                      />
                      <div className="errorbox">
                        {error && !applyFormData.email && <p className="validation">**Email is Required</p>}
                        {error && applyFormData.email &&
                          (!GeneralUtils.validationForEmail(applyFormData.email)) && <p className="validation">**Invalid Email</p>}
                      </div>

                    </Form.Group>

                  </Col>
                </Row>



                <Row>

                  <Col lg={6} >
                    <Form.Group controlId="formName">
                      <Form.Label>Phone No*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Phone Number"
                        name="phone"
                        maxLength={10}
                        value={applyFormData.phone}
                        onChange={(e) => {
                          if (e.target.value.length <= 15) {
                            handleInputChange(e)
                          }
                        }
                        }
                      />




                      <div className="errorbox">
                        {error && applyFormData.phone && applyFormData.phone.length > 15 && <p className="validation">**Please enter valid Phone Number</p>}
                        {error && applyFormData.phone && applyFormData.phone.length < 10 &&

                          <p className="validation">**Please enter valid Phone Number</p>

                        }  {error && !applyFormData.phone &&
                          <div className="errorbox">
                            <p className="validation">**Phone Number is Required</p>
                          </div>
                        }
                      </div>

                    </Form.Group>

                  </Col>
                  <Col lg={6} >
                    <Form.Group controlId="formName">
                      <Form.Label>Position</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Position"
                        name="position"
                        value={applyFormData.position}
                        onChange={handleInputChange}
                        disabled
                      />
                      <div className="errorbox">
                        {error && !applyFormData.position && <p className="validation">**Position is Required</p>}
                      </div>

                    </Form.Group>

                  </Col>

                </Row>


                <Row>

                  <Col lg={6} >
                    {/* <Form.Group controlId="formName">
                      <Form.Label>Job Location</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Location"
                        name="job_location"
                        value={applyFormData.job_location}
                        onChange={handleInputChange}
                      />
                      <div className="errorbox">
                        {error && !applyFormData.job_location && <p className="validation">**Location is Required</p>}
                      </div>
                    </Form.Group>
                     */}

                    <Form.Group controlId="formName">
                      <Form.Label>Job Location</Form.Label>
                      <Form.Control
                        as="select"
                        name="job_location"
                        value={applyFormData.job_location}
                        onChange={handleInputChange}
                        className='form-select'
                      >

                        {jobLocation.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        ))}
                      </Form.Control>
                      <div className="errorbox">
                        {error && !applyFormData.job_location && <p className="validation">**Location is Required</p>}
                      </div>
                    </Form.Group>


                  </Col>
                  <Col lg={6} >
                    <Form.Group controlId="formResume">
                      <Form.Label>Upload your resume*</Form.Label>
                      <input
                        label="Choose file"
                        type='file'
                        className='mt-1'
                        custom
                        onChange={handleFileChange}
                      />
                      {loader && <Spinner animation="border" variant="dark" />}
                      <div className="errorbox">
                        {error && !applyFormData.resume && <p className="validation">**Resume is Required</p>}
                        {error && applyFormData.resume && !applyFormData.resume.includes('.pdf') && !applyFormData.resume.includes('.doc') && !applyFormData.resume.includes('.docx') && <p className="validation">**Only pdf, doc and docx file are allowed</p>}
                      </div>

                      <span className="text-muted small mt-3">Note: Only pdf, doc and docx file are allowed</span>
                    </Form.Group>

                  </Col>
                </Row>

                <Row className="justify-content-between mt-2">
                  <Col xs={6} >
                    <Button className='btncontact mt-2 submit_btn' variant='light' disabled={loader} onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>

                  <Col xs={6} style={{textAlign:"right"}} >
                    <Button className='mt-2 btn btn-light cancel_btn' variant='light' onClick={handleCloseModal}>
                      Cancel
                    </Button>
                  </Col>
                </Row>

              </Form>
            }

          </Modal.Body>
        </Modal>
      </main>

    </>

  );
};

export default ApplyNowModal;