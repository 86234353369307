import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { contactPostData } from "../service/contactService";
import Swal from "sweetalert2";
import GeneralUtils from "../utils/GeneralUtils";
import { Button } from "react-bootstrap";
import Loader from "../component/Loader";
import { useLocation, useNavigate, Link } from "react-router-dom";

const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const messageToChatbot = location?.state?.demoNeededFor;
  console.log("messageToChatbot", messageToChatbot);

  useEffect(() => {
    if (messageToChatbot) {
      setContactData({
        ...contactData,
        demoNeededFor: messageToChatbot,
      });
    } else {
      setContactData({
        ...contactData,
        demoNeededFor: "Generic",
      });
    }
  }, [messageToChatbot]);

  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    organization: "",
    organizationSize: "",
    demoNeededFor: "",
  });

  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!contactData.phone) {
      setError(true);
      return;
    } else if (contactData.phone.length < 10) {
      setError(true);
      return;
    } else if (contactData.phone.length > 15) {
      setError(true);
      return;
    }

    if (!contactData.name || !contactData.email || !contactData.phone) {
      setError(true);
      return;
    } else if (!GeneralUtils.validationForEmail(contactData.email)) {
      setError(true);
      return;
    } else {
      try {
        const fromData = {
          name: contactData.name,
          email: contactData.email,
          phone_country: contactData.country,
          phone: contactData.phone,
          company_name: contactData.organization,
          organization_size: contactData.organizationSize,
          demo_needed_for: contactData.demoNeededFor,
          message: "NA",
        };

        setLoader(true);
        console.log("ddddd", fromData);
        const data = await contactPostData(fromData);

        if (data.success) {
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Details submitted successfully",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });

          setError(false);

          setContactData({
            name: "",
            email: "",
            country: "",
            phone: "",
            organization: "",
            organizationSize: "",
            demoNeededFor: "",
          });
        }
      } catch (error) {
        setLoader(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message || "Something Went Wrong",
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header activelink="contact" />

      {/* {loader && <div className="loader-container" id="loaderContainer">
        <Spinner animation="border" variant="dark" />
      </div>} */}

      {loader && <Loader />}

      <main>
        {/* <!--Page Banner--> */}
        <section
          className="hero-bannercareer position-relative"
          style={{ height: "40vw" }}
        >
          <div className="container py-lg-2">
            <div className="col-xxl-12 mx-auto">
              {/* <div className="contact icon-back" onClick={() => navigate(-1)} style={{cursor:"pointer"}}>
                <Link >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </Link>
              </div>

              <Link className="text-white">
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                {" "}Back
              </Link> */}

              <div className="row pb-5 fade-in-element left">
                <div className="col-xxl-8 col-lg-9">
                  <h1 className="Careers-herobannertext2">
                    Contact Us/ Request a Demo
                  </h1>
                  <p className="lead-careertext">
                    Drop your details and our team will reach out soon!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="form-incontact">
            <form>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <input
                    type="text"
                    className="form-control form__controlbbns"
                    placeholder="Your Name*"
                    required
                    name="name"
                    value={contactData.name}
                    onChange={(e) => {
                      if (GeneralUtils.validationForName(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                  />
                  <div className="errorbox">
                    {error && !contactData.name && (
                      <p className="validation">**Full Name is Required</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <input
                    type="text"
                    className="form-control form__controlbbns"
                    placeholder="Email ID*"
                    required
                    name="email"
                    value={contactData.email}
                    onChange={handleChange}
                  />
                  <div className="errorbox">
                    {error && !contactData.email && (
                      <p className="validation">**Email is Required</p>
                    )}
                    {error &&
                      contactData.email &&
                      !GeneralUtils.validationForEmail(contactData.email) && (
                        <p className="validation">**Invalid Email</p>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <select
                    className="form-select form__controlbbns"
                    id="validationDefault04"
                    name="country"
                    value={contactData.country}
                    onChange={handleChange}
                  >
                    <option value="">Country</option>
                    <option value="United States">United States</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="India">India</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa (US)">
                      American Samoa (US)
                    </option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla (BOT)">Anguilla (BOT)</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Artsakh">Artsakh</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda (BOT)">Bermuda (BOT)</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire (Netherlands)">
                      Bonaire (Netherlands)
                    </option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Virgin Islands (BOT)">
                      British Virgin Islands (BOT)
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands (BOT)">
                      Cayman Islands (BOT)
                    </option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="DR Congo">DR Congo</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Eswatini">Eswatini</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (BOT)">
                      Falkland Islands (BOT)
                    </option>
                    <option value="Faroe Islands (Denmark)">
                      Faroe Islands (Denmark)
                    </option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana (France)">
                      French Guiana (France)
                    </option>
                    <option value="French Polynesia (France)">
                      French Polynesia (France)
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar (BOT)">Gibraltar (BOT)</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland (Denmark)">
                      Greenland (Denmark)
                    </option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe (France)">
                      Guadeloupe (France)
                    </option>
                    <option value="Guam (US)">Guam (US)</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey (Crown Dependency)">
                      Guernsey (Crown Dependency)
                    </option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man (Crown Dependency)">
                      Isle of Man (Crown Dependency)
                    </option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Ivory Coast">Ivory Coast</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey (Crown Dependency)">
                      Jersey (Crown Dependency)
                    </option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique (France)">
                      Martinique (France)
                    </option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte (France)">Mayotte (France)</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">Micronesia</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat (BOT)">Montserrat (BOT)</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Caledonia (France)">
                      New Caledonia (France)
                    </option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island (Australia)">
                      Norfolk Island (Australia)
                    </option>
                    <option value="North Korea">North Korea</option>
                    <option value="North Macedonia">North Macedonia</option>
                    <option value="Northern Cyprus">Northern Cyprus</option>
                    <option value="Northern Mariana Islands (US)">
                      Northern Mariana Islands (US)
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn Islands (BOT)">
                      Pitcairn Islands (BOT)
                    </option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico (US)">Puerto Rico (US)</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Réunion (France)">Réunion (France)</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saba (Netherlands)">
                      Saba (Netherlands)
                    </option>
                    <option value="Saint Barthélemy (France)">
                      Saint Barthélemy (France)
                    </option>
                    <option value="Saint Helena, Ascension and Tristan da Cunha (BOT)">
                      Saint Helena, Ascension and Tristan da Cunha (BOT)
                    </option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin (France)">
                      Saint Martin (France)
                    </option>
                    <option value="Saint Pierre and Miquelon (France)">
                      Saint Pierre and Miquelon (France)
                    </option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="São Tomé and Príncipe">
                      São Tomé and Príncipe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Eustatius (Netherlands)">
                      Sint Eustatius (Netherlands)
                    </option>
                    <option value="Sint Maarten (Netherlands)">
                      Sint Maarten (Netherlands)
                    </option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen (Norway)">
                      Svalbard and Jan Mayen (Norway)
                    </option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau (NZ)">Tokelau (NZ)</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Transnistria">Transnistria</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands (BOT)">
                      Turks and Caicos Islands (BOT)
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="U.S. Virgin Islands (US)">
                      U.S. Virgin Islands (US)
                    </option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Wallis and Futuna (France)">
                      Wallis and Futuna (France)
                    </option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <div className="errorbox">
                    {/* {error && !contactData.country && <p className="validation">**Country is Required</p>} */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <input
                    type="number"
                    className="form-control form__controlbbns"
                    placeholder="Phone Number*"
                    name="phone"
                    value={contactData.phone}
                    onChange={(e) => {
                      if (e.target.value.length <= 15) {
                        handleChange(e);
                      }
                    }}
                    required
                  />
                  <div className="errorbox">
                    {error && !contactData.phone && (
                      <p className="validation">**Phone Number is Required</p>
                    )}
                    {
                      error &&
                      contactData.phone &&
                      contactData.phone.length < 10 && (
                        <p className="validation">
                          **Please enter valid Phone Number
                        </p>
                      )
                    }
                    {
                      error &&
                      contactData.phone &&
                      contactData.phone.length > 15 && (
                        <p className="validation">
                          **Please enter valid Phone Number
                        </p>
                      )

                    }


                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <input
                    type="text"
                    className="form-control form__controlbbns"
                    placeholder="Organization Name"
                    name="organization"
                    value={contactData.organization}
                    onChange={handleChange}
                  />
                  <div className="errorbox">
                    {/* {error && !contactData.organization && <p className="validation">**Organization Name is Required</p>} */}
                  </div>
                </div>
                <div className="col-lg-6 mb-2">
                  <select
                    className="form-select form__controlbbns"
                    id="validationDefault04"
                    name="organizationSize"
                    value={contactData.organizationSize}
                    onChange={handleChange}
                  >
                    <option value={""}>Organization Size</option>
                    <option value="1-50 employees">1-50 employees</option>
                    <option value="51-200 employees">51-200 employees</option>
                    <option value="201-500 employees">201-500 employees</option>
                    <option value="501-1,000 employees">
                      501-1,000 employees
                    </option>
                    <option value="1,001-5,000 employees">
                      1,001-5,000 employees
                    </option>
                    <option value="5,001-10,000 employees">
                      5,001-10,000 employees
                    </option>
                    <option value="10,001-50,000 employees">
                      10,001-50,000 employees
                    </option>
                    <option value="50,001-100,000 employees">
                      50,001-100,000 employees
                    </option>
                  </select>
                  <div className="errorbox">
                    {/* {error && !contactData.organizationSize && <p className="validation">**Organization Size is Required</p>} */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mb-2">
                  <select
                    className="form-select form__controlbbns"
                    id="validationDefault04"
                    name="demoNeededFor"
                    value={contactData.demoNeededFor}
                    onChange={handleChange}
                  >
                    <option value="">Demo Needed For</option>
                    <option value="Generic">General</option>
                    <option value="AI Chatbots">AI Chatbots</option>
                    <option value="Enterprise AI Copilots">
                      Enterprise AI Copilots
                    </option>
                    <option value="Finance">Finance</option>
                    <option value="Retail">Retail</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Manufacturing">Manufacturing</option>
                  </select>
                  <div className="errorbox">
                    {/* {error && !contactData.demoNeededFor && <p className="validation">**Demo Needed For is Required</p>} */}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  className="submit_btn"
                  variant="light"
                  onClick={handleSubmit}
                  style={{ marginRight: "20px" }}
                >
                  Submit
                </Button>

                <Link className="text-black" style={{ fontSize: "1.125rem" }}
                  onClick={() => navigate(-1)}>
                  Back
                </Link>
              </div>
              <div>

              </div>
            </form>
          </section>
          <img
            src="/img/contact/contact-bannershort.png"
            alt=""
            className="circle-elipsse-retailer earth"
          />
        </section>

        {/* <!--Contact Us--> */}
        <section className="padding__control__backmedia">
          <div className="container-fluid px-md-5 px-4">
            <div className="col-lg-11 mx-auto">
              <h2 className="career-headingwhyjoin mb-lg-5 mb-sm-2 pb-lg-4 pb-sm-1">
                Meet us here
              </h2>
              <div className="row px-md-3">
                <div className="col-lg-4 p-0">
                  <div className="card_control_contact bg-black p-md-5 p-3">
                    <div className="card-body">
                      <h3 className="industries-retailercardheading">Delhi-NCR</h3>
                      <p className="industries-retailerpara">
                      F-7, Serenia, IHDP Business Park,<br/>
                      Sector 127, Noida (UP) 201303
                      </p>
                      <div className="d-flex align-items-center mb-md-4 mb-2">
                        <img
                          src="/img/contact/msg.png"
                          alt=""
                          className="img-icon"
                        />
                        <p className="small__text">usa@Acompworld.ai</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/img/contact/phone.png"
                          alt=""
                          className="img-icon"
                        />
                        <p className="small__text">(973) 796-2300</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-0">
                  <div className="card_control_contact p-md-5 p-3">
                    <div className="card-body">
                      <h3 className="industries-textheading">Bhopal</h3>
                      <p className="industries-paratext">
                      S.PACE, E-3/114, Arera Colony,<br/>
10 Number, Bhopal (MP) 462016
                      </p>

                      <div className="d-flex align-items-center mb-md-4 mb-2">
                        {" "}
                        <img
                          src="/img/contact/black-msg.png"
                          alt=""
                          className="img-icon"
                        />{" "}
                        <p className="small__textblck">info@Acompworld.com</p>
                      </div>
                      <div className="d-flex align-items-center">
                        {" "}
                        <img
                          src="/img/contact/phoneblack.png"
                          alt=""
                          className="img-icon"
                        />{" "}
                        <p className="small__textblck">+91-9900000000</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-0">
                  <div className="card_control_contact bg-black p-md-5 p-3">
                    <div className="card-body">
                      <h3 className="industries-retailercardheading">Singrauli</h3>
                      <p className="industries-retailerpara">
                      Aptech Campus, Govt College Road,<br/>
                      Waidhan, Singrauli (MP) 486886
                      </p>

                      <div className="d-flex align-items-center mb-md-4 mb-2">
                        {" "}
                        <img
                          src="/img/contact/msg.png"
                          alt=""
                          className="img-icon"
                        />{" "}
                        <p className="small__text">info@Acompworld.com</p>
                      </div>
                      <div className="d-flex align-items-center">
                        {" "}
                        <img
                          src="/img/contact/phone.png"
                          alt=""
                          className="img-icon"
                        />{" "}
                        <p className="small__text">+91-9900000000</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Contact;
