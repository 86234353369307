import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link,useNavigate } from 'react-router-dom'
import ServicesDataAnalyticsCarousel from './ServicesDataAnalyticsCarousel'
import ErpCarousel from './ErpCarousel'
import WebPortalCarousel from './WebPortalCarousel'

const ServicesWebPortals = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Retail",  // Replace this with your actual data
            },
          });
    }

  return (
    <>
        <Header activelink='industries'/>

        <main style={{backgroundColor:'#fff'}}>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5">
                    <div className="col-xxl-12 mx-auto">
                        <div className="row py-5">
                            <div className="col-xxl-8 col-lg-9 fade-in-element left">
                                <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-retail">Retail</Link></span></p>
                                <h1 className="Careers-herobannertext">Web Portals, Intranets & Content Management Systems (CMS)</h1>
                                <p className="lead-careertext">Enhance Collaboration and Content Delivery

                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <img src="/img/industries-retailer/object.png" alt="" className="circle-elipsse-retailer"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light">
                <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
                    <div className="col-lg-11 mx-auto">                       
                        <div className="">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                            Acompworld specializes in designing and implementing web portals, intranets, and CMS solutions that enhance collaboration, streamline communication, and simplify content management. Our solutions are built for scalability, security, and ease of use, enabling businesses to operate more efficiently and stay connected. </p>
                        </div>                        
                    </div>
                </div>
            </section>

            {/* <!--AI Solutions for Retail--> */}
            <section>
                <div className=" container-fluid px-lg-5 pt-md-2">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">Why Choose Our Web & CMS Services?
                        </h2>                      
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Tailored Solutions</h3>
                                    <p className="industries-retailerpara">
                                    Custom-designed portals and intranets aligned with your organizational needs.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer2.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">User-Friendly CMS</h3>
                                    <p className="industries-paratext">
                                    Empower non-technical users to manage content effortlessly.
                                         </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer3.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Enhanced Collaboration</h3>
                                    <p className="industries-paratext">
                                    Foster better communication and document sharing across teams.

                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer4.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Secure Platforms</h3>
                                    <p className="industries-retailerpara">
                                    Built with advanced security protocols to protect sensitive data.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer5.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Multi-Language Support</h3>
                                    <p className="industries-retailerpara">
                                    Deliver content seamlessly across different languages and regions.

                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            {/* <!--Driving Tangible Growth in the Retail Space--> */}
            <section className="epb-lg-5 mb-lg-5 pt-lg-5">
                <div className="container-fluid px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin">Our Key Offerings</h2>                     
                    </div>
                </div>
                <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                <h5>Corporate Intranets</h5>
                                <p> Streamline internal communication and create a centralized hub for company information.
                                </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Custom Web Portals</h5>
                                    <p>  Build secure, scalable platforms for employee, customer, or partner engagement.

                                    </p>       
                                </div> 
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Content Management Systems (CMS)</h5>
                                    <p>Develop intuitive CMS solutions for quick and easy content updates.

                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Knowledge Sharing Platforms</h5>
                                    <p> Enable efficient sharing and organization of critical information.
                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Multi-Device Compatibility</h5>
                                    <p> Ensure optimal performance across desktops, tablets, and smartphones.

                                    </p>       
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </section>

            {/* <!--Trust Acompworld--> */}
            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Who Benefits from These Solutions?
                            </h3> 
                            <p>Our web portals, intranets, and CMS services cater to organizations aiming to:

                            </p>
                            <p>
                                <b className="text-white">Improve team collaboration and knowledge sharing.


                            </b> </p>
                            <p>
                                <b className="text-white">Simplify content creation and management processes.


                                </b> 
                            </p>
                            <p>
                                <b className="text-white">Enhance engagement with employees, customers, or partners.


                                </b> 
                            </p>
                        </div>
                    </div>
                </div>
            </section>           
        </main>
        <section>
                <div className="container">
                    <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
                    <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                    Pathbreaking Pilots
                    </h2>
                    </div>
                    
                </div>
                <WebPortalCarousel />
            </section>
        
        {/* <!-- Footer match --> */}
        <section className="hero-bannercareer border-bottomfooter fade-in-element">
            <div className="container py-4">
                <div className="col-lg-12 mx-auto">
                    <div className="row py-md-5 py-2">
                        <div className="col-lg-12">
                            <h1 className="chat-llm">
                            Transform Collaboration with Acompworld’s Digital Solutions

                            </h1>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                            <span onClick={handleDemoClick} className="btn btn-consulation">Let’s Build Together</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <Footer />
    </>
  )
}

export default ServicesWebPortals


