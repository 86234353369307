import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link,useNavigate } from 'react-router-dom'
import ServicesDataAnalyticsCarousel from './ServicesDataAnalyticsCarousel'

const ServicesDataAnalytics = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Retail",  // Replace this with your actual data
            },
          });
    }

  return (
    <>
        <Header activelink='industries'/>

        <main style={{backgroundColor:'#fff'}}>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5">
                    <div className="col-xxl-12 mx-auto">
                        <div className="row py-5">
                            <div className="col-xxl-8 col-lg-9 fade-in-element left">
                                <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-retail">Retail</Link></span></p>
                                <h1 className="Careers-herobannertext">Data Analytics, BI & Dashboards</h1>
                                <p className="lead-careertext">Empowering Data-Driven Decisions
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <img src="/img/industries-retailer/object.png" alt="" className="circle-elipsse-retailer"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light">
                <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
                    <div className="col-lg-11 mx-auto">                       
                        <div className="">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                            In today’s fast-paced world, data is the key to making informed business decisions. Acompworld’s Data Analytics, Business Intelligence (BI), and Dashboard solutions help you unlock the potential of your data. From uncovering trends to tracking KPIs, we provide the tools and insights you need to drive operational efficiency and strategic growth.

                            </p>
                        </div>                        
                    </div>
                </div>
            </section>

            {/* <!--AI Solutions for Retail--> */}
            <section>
                <div className=" container-fluid px-lg-5 pt-md-2">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">Why Choose Our Data Analytics Services?
                        </h2>                      
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Advanced Analytics</h3>
                                    <p className="industries-retailerpara">
                                    Leverage cutting-edge tools to analyze complex datasets and uncover valuable insights.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer2.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Custom Dashboards</h3>
                                    <p className="industries-paratext">
                                    Access real-time, interactive dashboards tailored to your business metrics and goals.

                                         </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer3.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Data Visualization</h3>
                                    <p className="industries-paratext">
                                    Simplify complex data with intuitive charts, graphs, and visuals for better decision-making.

                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer4.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Actionable Reports</h3>
                                    <p className="industries-retailerpara">
                                    Generate detailed reports with drill-down capabilities to pinpoint key areas of improvement.
  
                                          </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer5.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Seamless Integration</h3>
                                    <p className="industries-retailerpara">
                                    Integrates effortlessly with your existing systems for a unified view of your operations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            {/* <!--Driving Tangible Growth in the Retail Space--> */}
            <section className="epb-lg-5 mb-lg-5 pt-lg-5">
                <div className="container-fluid px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin">Our Key Offerings</h2>                     
                    </div>
                </div>
                <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                <h5>Business Intelligence Dashboards</h5>
                                <p>Empower teams with real-time analytics and visualized KPIs.
                                </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Predictive Analytics</h5>
                                    <p>Identify trends and forecast outcomes to stay ahead of market changes.
                                    </p>       
                                </div> 
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Custom Reporting Tools</h5>
                                    <p>Create tailored reports for finance, operations, sales, and more.
                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Data Integration & Warehousing</h5>
                                    <p>Centralize data from multiple sources for a single source of truth.

                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Self-Service Analytics</h5>
                                    <p>Equip users with easy-to-use tools to explore data without technical dependencies.

                                    </p>       
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </section>

            {/* <!--Trust Acompworld--> */}
            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Who Benefits from These Solutions?</h3> 
                            <p>Our Data Analytics and BI services are perfect for organizations in industries like energy, manufacturing, and beyond, helping them to:
                            </p>
                            <p>
                                <b className="text-white">Optimize operations through informed decision-making.
                            </b> </p>
                            <p>
                                <b className="text-white">Enhance efficiency with real-time performance tracking.
                                </b> 
                            </p>
                            <p>
                                <b className="text-white">Identify cost-saving opportunities through predictive insights.
                                </b> 
                            </p>
                        </div>
                    </div>
                </div>
            </section>           
        </main>
        <section>
                <div className="container">
                    <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
                    <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                    Pathbreaking Pilots
                    </h2>
                    </div>
                    
                </div>
                < ServicesDataAnalyticsCarousel/>
            </section>
        
        {/* <!-- Footer match --> */}
        <section className="hero-bannercareer border-bottomfooter fade-in-element">
            <div className="container py-4">
                <div className="col-lg-12 mx-auto">
                    <div className="row py-md-5 py-2">
                        <div className="col-lg-12">
                            <h1 className="chat-llm">Unlock the Power of Your Data</h1>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                            <span onClick={handleDemoClick} className="btn btn-consulation">Explore Our Analytics Services</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <Footer />
    </>
  )
}

export default ServicesDataAnalytics
