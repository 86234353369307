import React,{useEffect} from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link,useNavigate } from 'react-router-dom'
import ServicesDataAnalyticsCarousel from './ServicesDataAnalyticsCarousel'
import ErpCarousel from './ErpCarousel'

const ServicesERPWorkflow = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const handleDemoClick = () => {
        navigate('/contact', {
            state: {
              demoNeededFor: "Retail",  // Replace this with your actual data
            },
          });
    }

  return (
    <>
        <Header activelink='industries'/>

        <main style={{backgroundColor:'#fff'}}>
            {/* <!--Page Banner--> */}
            <section className="hero-bannercareer">
                <div className="container py-lg-5">
                    <div className="col-xxl-12 mx-auto">
                        <div className="row py-5">
                            <div className="col-xxl-8 col-lg-9 fade-in-element left">
                                <p className="small__by_text"> <Link to="/industries-finance"> Industries /</Link> <span><Link to="/industries-retail">Retail</Link></span></p>
                                <h1 className="Careers-herobannertext">ERP Systems & Workflow Automation</h1>
                                <p className="lead-careertext">Streamlining Operations for Maximum Efficiency
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                                    <span onClick={handleDemoClick} className="btn btn-careerseeopen">Request a demo</span>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <img src="/img/industries-retailer/object.png" alt="" className="circle-elipsse-retailer"/>
            </section>

            {/* <!--Para--> */}
            <section className="bg-gray-light">
                <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
                    <div className="col-lg-11 mx-auto">                       
                        <div className="">
                            <p className="lead-careerbyline mb-4 fade-in-element bottom">
                            Acompworld’s ERP (Enterprise Resource Planning) and Workflow Automation services are designed to transform your business processes. By centralizing operations and automating repetitive tasks, we help you achieve efficiency, accuracy, and agility, enabling your team to focus on strategic goals.
                            </p>
                        </div>                        
                    </div>
                </div>
            </section>

            {/* <!--AI Solutions for Retail--> */}
            <section>
                <div className=" container-fluid px-lg-5 pt-md-2">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">Why Choose Our ERP & Automation Services?

                        </h2>                      
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
                    <div className="row">
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">End-to-End Solutions</h3>
                                    <p className="industries-retailerpara">
                                    From ERP system implementation to process automation, we cover every aspect of operational transformation.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer2.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Customized Workflows</h3>
                                    <p className="industries-paratext">
                                    Tailored to your unique business processes for seamless integration and maximum impact.

                                         </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="card card-customindustries mt-lg-0 mt-sm-4">
                                <div className="card-body px-lg-5 px-sm-2 py-4">
                                    <img src="/img/industries-retailer/icon-retailer3.svg" alt="" className="my-3"/>
                                    <h3 className="industries-textheading">Enhanced Collaboration</h3>
                                    <p className="industries-paratext">
                                    Improve inter-departmental coordination and reduce bottlenecks.

                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer4.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Cost & Time Savings</h3>
                                    <p className="industries-retailerpara">
                                    Eliminate manual tasks and reduce errors to save time and resources.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                            <div className="card card-customindustriesdack">
                                <div className="card-body px-lg-5 py-4">
                                    <img src="/img/industries-retailer/icon-retailer5.svg" alt="" className="my-3"/>
                                    <h3 className="industries-retailercardheading">Scalable Systems</h3>
                                    <p className="industries-retailerpara">
                                    Flexible solutions that grow with your business needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            {/* <!--Driving Tangible Growth in the Retail Space--> */}
            <section className="epb-lg-5 mb-lg-5 pt-lg-5">
                <div className="container-fluid px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <h2 className="career-headingwhyjoin">Our Key Offerings</h2>                     
                    </div>
                </div>
                <div className="container-fluid mt-md-5 mt-2 px-lg-5">
                    <div className="col-lg-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                <h5>Custom ERP Systems</h5>
                                <p> Develop and implement ERP systems tailored to your industry and workflows.
                                </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Workflow Automation</h5>
                                    <p> Automate approvals, reporting, and operational tasks for improved productivity.
                                    </p>       
                                </div> 
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Integration with Leading Platforms</h5>
                                    <p>Expertise in platforms like SAP, Salesforce, and others for seamless adoption.

                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Performance Dashboards</h5>
                                    <p> Real-time visibility into workflow performance for better decision-making.

                                    </p>       
                                </div>
                            </div>
                            <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                                <div className="card-driving-tangible">
                                    <h5>Cloud-Based Solutions</h5>
                                    <p>Access your ERP and workflow systems anytime, anywhere for ultimate flexibility.
                                    </p>       
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </section>

            {/* <!--Trust Acompworld--> */}
            <section className="industries-impactai">
                <div className="container">
                    <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
                        <div className="card-carouselindutries fade-in-element right">
                            <h3 className="pt-lg-5 mb-lg-4">Who Benefits from ERP & Workflow Automation?
                            </h3> 
                            <p>Our solutions cater to organizations looking to:
                            </p>
                            <p>
                                <b className="text-white">Enhance operational efficiency through centralized systems.

                            </b> </p>
                            <p>
                                <b className="text-white">Streamline workflows and reduce dependency on manual processes.

                                </b> 
                            </p>
                            <p>
                                <b className="text-white">Improve collaboration and real-time decision-making.

                                </b> 
                            </p>
                        </div>
                    </div>
                </div>
            </section>           
        </main>
        <section>
                <div className="container">
                    <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
                    <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
                    Pathbreaking Pilots
                    </h2>
                    </div>
                    
                </div>
                <ErpCarousel />
            </section>
        
        {/* <!-- Footer match --> */}
        <section className="hero-bannercareer border-bottomfooter fade-in-element">
            <div className="container py-4">
                <div className="col-lg-12 mx-auto">
                    <div className="row py-md-5 py-2">
                        <div className="col-lg-12">
                            <h1 className="chat-llm">
                            Transform How You Work with ERP & Automation
                            </h1>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                            <span onClick={handleDemoClick} className="btn btn-consulation">Request a Free Consultation</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <Footer />
    </>
  )
}

export default ServicesERPWorkflow

