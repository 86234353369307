import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/CustomDevelopment.css";

const services = [
  {
    title: "Data Analytics, BI & Dashboards",
    description:
      "Empowering data-driven decisions through advanced analytics and customized business intelligence dashboards.",
    icon: "/img/home/dataanalytics.svg",
  },
  {
    title: "ERP Systems & Workflow Automation",
    description:
      "Streamlining operational efficiency via customized ERP systems and automated workflows, including SAP & Salesforce.",
    icon: "/img/home/erpsystem.svg",
  },
  {
    title: "Web Portals, Intranets & Content Management Systems (CMS)",
    description:
      "Enhancing collaboration and content delivery with secure, scalable web and intranet solutions.",
    icon: "/img/home/webportal.svg",
  },
  {
    title: "Mobile Applications (iOS & Android)",
    description:
      "Building high-performance mobile applications to engage customers and optimize business processes.",
    icon: "/img/home/mobileapplication.svg",
  },
  {
    title: "Legacy Application Modernization & Migration",
    description:
      "Upgrading and transforming outdated systems into modern, scalable applications while ensuring seamless migration.",
    icon: "/img/home/legacy.svg",
  },
  {
    title: "IoT (Internet of Things) Solutions",
    description:
      "Building smart, connected systems to streamline operations and gather valuable insights from sensors and devices.",
    icon: "/img/home/iot.svg",
  },
];

const CustomDevelopment = () => {
  return (
    <section className="custom-development-section pb-lg-5 fade-in-element bottom ">
      <div className="container">
        <div className="row py-md-4 py-2">
          <div className="col-lg-6 pt-lg-5 py-2">
            <h2 className="display-4 fw-bold mb-4">
              Custom Development Services
            </h2>
            <p className="fs-5 mb-4 fw-normal lh-2 Transformative-sectionp">
              Acompworld can help you solve your most pressing challenges and
              make better business decisions.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 service-card1 s-hover">
          <div className="service-card ">
            <div className="icon">
              <img src="/img/home/dataanalytics.svg" />
            </div>
            <h5
              className="mt-3 fw-bold"
            >
              Data Analytics, BI & Dashboards
            </h5>
            <p>
              Empowering data-driven decisions through advanced analytics and
              customized business intelligence dashboards.
            </p>
            <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>

          </div>
        </div>
        <div className="col-lg-4 col-md-6 service-card1 s-hover">
          <div className="service-card">
            <div className="icon">
              <img src="/img/home/erpsystem.svg" />
            </div>
            <h5
              className="mt-3 fw-bold
                "
            >
           ERP Systems & Workflow Automation
            </h5>
            <p>
            Streamlining operational efficiency via customized ERP systems and automated workflows, including SAP & Salesforce.
            </p>
            <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 service-card2 s-hover">
          <div className="service-card">
            <div className="icon">
              <img src="/img/home/webportal.svg" />
            </div>
            <h5
              className="mt-3 fw-bold
                "
            >
          Web Portals, Intranets & Content Management Systems (CMS)
            </h5>
            <p>
            Enhancing collaboration and content delivery with secure, scalable web and intranet solutions.
              </p>
              <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 service-card-border-right s-hover">
          <div className="service-card">
            <div className="icon">
              <img src="/img/home/mobileapplication.svg" />
            </div>
            <h5
              className="mt-3 fw-bold
                "
            >
       Mobile Applications (iOS & Android)
            </h5>
            <p>
            Building high-performance mobile applications to engage customers and optimize business processes.
          </p>
          <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 service-card-border-right s-hover">
          <div className="service-card">
            <div className="icon">
              <img src="/img/home/legacy.svg" />
            </div>
            <h5
              className="mt-3 fw-bold
                "
            >
           Legacy Application Modernization & Migration
            </h5>
            <p>
            Upgrading and transforming outdated systems into modern, scalable applications while ensuring seamless migration.
             </p>
             <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 s-hover ">
          <div className="service-card">
            <div className="icon">
              <img src="/img/home/iot.svg" />
            </div>
            <h5
              className="mt-3 fw-bold
                "
            >
          IoT (Internet of Things) Solutions
            </h5>
            <p>
            Building smart, connected systems to streamline operations and gather valuable insights from sensors and devices.
            </p>
            <div className="arrow-icon">
              <img src="/img/home/service-arrow.svg" />
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomDevelopment;
