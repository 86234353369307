import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import ServicesDataAnalyticsCarousel from "./ServicesDataAnalyticsCarousel";
import ErpCarousel from "./ErpCarousel";
import WebPortalCarousel from "./WebPortalCarousel";
import LegacyCarousel from "./LegacyCarousel";
import IotCarousel from "./IotCarousel";

const ServicesIOTSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate("/contact", {
      state: {
        demoNeededFor: "Retail", // Replace this with your actual data
      },
    });
  };

  return (
    <>
      <Header activelink="industries" />

      <main style={{ backgroundColor: "#fff" }}>
        {/* <!--Page Banner--> */}
        <section className="hero-bannercareer">
          <div className="container py-lg-5">
            <div className="col-xxl-12 mx-auto">
              <div className="row py-5">
                <div className="col-xxl-8 col-lg-9 fade-in-element left">
                  <p className="small__by_text">
                    {" "}
                    <Link to="/industries-finance"> Industries /</Link>{" "}
                    <span>
                      <Link to="/industries-retail">Retail</Link>
                    </span>
                  </p>
                  <h1 className="Careers-herobannertext">IoT Solutions</h1>
                  <p className="lead-careertext">
                    {" "}
                    Connect, Monitor, and Optimize Your Operations
                  </p>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-5 mt-sm-2">
                    <span
                      onClick={handleDemoClick}
                      className="btn btn-careerseeopen"
                    >
                      Request a demo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/img/industries-retailer/object.png"
            alt=""
            className="circle-elipsse-retailer"
          />
        </section>

        {/* <!--Para--> */}
        <section className="bg-gray-light">
          <div className="px-lg-5 pt-lg-5 mb-lg-5 pt-sm-2 px-sm-2 pb-md-5 pb-3 container-fluid">
            <div className="col-lg-11 mx-auto">
              <div className="">
                <p className="lead-careerbyline mb-4 fade-in-element bottom">
                  Acompworld’s IoT (Internet of Things) solutions help
                  businesses build intelligent, connected systems that
                  streamline operations, enhance efficiency, and provide
                  valuable real-time insights. From industrial monitoring to
                  smart devices, our IoT solutions are designed to transform the
                  way you work.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!--AI Solutions for Retail--> */}
        <section>
          <div className=" container-fluid px-lg-5 pt-md-2">
            <div className="col-lg-11 mx-auto">
              <h2 className="career-headingwhyjoin mt-md-5 mt-4 mb-sm-2 pb-sm-1">
                Why Choose Acompworld’s IoT Solutions?
              </h2>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid py-lg-5 mt-md-0 fade-in-element">
            <div className="row">
              <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                <div className="card card-customindustriesdack">
                  <div className="card-body px-lg-5 py-4">
                    <img
                      src="/img/industries-retailer/icon-retailer.svg"
                      alt=""
                      className="my-3"
                    />
                    <h3 className="industries-retailercardheading">
                      Seamless Connectivity
                    </h3>
                    <p className="industries-retailerpara">
                      Integrate sensors, devices, and systems into a unified
                      network.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p-0">
                <div className="card card-customindustries mt-lg-0 mt-sm-4">
                  <div className="card-body px-lg-5 px-sm-2 py-4">
                    <img
                      src="/img/industries-retailer/icon-retailer2.svg"
                      alt=""
                      className="my-3"
                    />
                    <h3 className="industries-textheading">
                      Real-Time Data Insights
                    </h3>
                    <p className="industries-paratext">
                      Monitor operations in real time for better
                      decision-making.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p-0">
                <div className="card card-customindustries mt-lg-0 mt-sm-4">
                  <div className="card-body px-lg-5 px-sm-2 py-4">
                    <img
                      src="/img/industries-retailer/icon-retailer3.svg"
                      alt=""
                      className="my-3"
                    />
                    <h3 className="industries-textheading">
                      Enhanced Efficiency
                    </h3>
                    <p className="industries-paratext">
                      Automate processes and optimize workflows with IoT-driven
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                <div className="card card-customindustriesdack">
                  <div className="card-body px-lg-5 py-4">
                    <img
                      src="/img/industries-retailer/icon-retailer4.svg"
                      alt=""
                      className="my-3"
                    />
                    <h3 className="industries-retailercardheading">
                      Customizable Solutions
                    </h3>
                    <p className="industries-retailerpara">
                      Tailored to your unique operational needs and industry
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-lg-0 mt-sm-3 p-0">
                <div className="card card-customindustriesdack">
                  <div className="card-body px-lg-5 py-4">
                    <img
                      src="/img/industries-retailer/icon-retailer5.svg"
                      alt=""
                      className="my-3"
                    />
                    <h3 className="industries-retailercardheading">
                      Secure Architecture
                    </h3>
                    <p className="industries-retailerpara">
                      Built with enterprise-grade security to protect sensitive
                      data and ensure compliance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Driving Tangible Growth in the Retail Space--> */}
        <section className="epb-lg-5 mb-lg-5 pt-lg-5">
          <div className="container-fluid px-lg-5">
            <div className="col-lg-11 mx-auto">
              <h2 className="career-headingwhyjoin">Our Key Offerings</h2>
            </div>
          </div>
          <div className="container-fluid mt-md-5 mt-2 px-lg-5">
            <div className="col-lg-11 mx-auto">
              <div className="row">
                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                  <div className="card-driving-tangible">
                    <h5>Industrial IoT (IIoT)</h5>
                    <p>
                      {" "}
                      Enable real-time monitoring and analytics for industrial
                      equipment and processes.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                  <div className="card-driving-tangible">
                    <h5>Smart Asset Tracking</h5>
                    <p>
                      {" "}
                      Manage and track assets efficiently with IoT-powered
                      location and condition monitoring.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                  <div className="card-driving-tangible">
                    <h5>Predictive Maintenance</h5>
                    <p>
                      Leverage IoT to detect potential issues and prevent
                      equipment failures.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                  <div className="card-driving-tangible">
                    <h5>Connected Workforce Solutions</h5>
                    <p>
                      Enhance safety and productivity with wearable IoT devices
                      and sensors.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-2 mt-2 fade-in-element left">
                  <div className="card-driving-tangible">
                    <h5>Custom IoT Applications</h5>
                    <p>
                      {" "}
                      Build specialized IoT solutions to address specific
                      challenges in your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Trust Acompworld--> */}
        <section className="industries-impactai">
          <div className="container">
            <div className="col-lg-6  ms-lg-auto mt-lg-5 pt-lg-5">
              <div className="card-carouselindutries fade-in-element right">
                <h3 className="pt-lg-5 mb-lg-4">
                  Who Benefits from IoT Solutions?
                </h3>
                <p>
                  Our IoT services are ideal for industries like energy,
                  manufacturing, and logistics, enabling businesses to:
                </p>
                <p>
                  <b className="text-white">
                    Optimize resource usage and reduce operational costs.
                  </b>{" "}
                </p>
                <p>
                  <b className="text-white">
                    Improve equipment uptime with predictive maintenance.
                  </b>
                </p>
                <p>
                  <b className="text-white">
                    Gain real-time visibility into operations for smarter
                    management.
                  </b>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <section>
        <div className="container">
          <div className="col-lg-12 mx-auto mt-lg-5 pt-lg-5 mt-sm-3 pt-sm-2 px-0">
            <h2 className="career-headingwhyjoin pb-4 mb-lg-5">
              Pathbreaking Pilots
            </h2>
          </div>
        </div>

        <IotCarousel />
      </section>

      {/* <!-- Footer match --> */}
      <section className="hero-bannercareer border-bottomfooter fade-in-element">
        <div className="container py-4">
          <div className="col-lg-12 mx-auto">
            <div className="row py-md-5 py-2">
              <div className="col-lg-12">
                <h1 className="chat-llm">Transform Operations with IoT</h1>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-lg-4 mt-sm-2">
                  <span
                    onClick={handleDemoClick}
                    className="btn btn-consulation"
                  >
                    Request a Consultation
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ServicesIOTSolutions;
