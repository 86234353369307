import React from "react";
import Slider from "react-slick";
import "../assets/css/TransformCarousel.css";
import { Link } from "react-router-dom";

const TransformCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.13,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="inner-slider-box  mb-5">
      <div className="container">
        <div className="row py-md-4 py-2">
          <div className="col-lg-7 pt-lg-5 py-2">
            <h2 className="display-4 fw-bold mb-4">
              We transform companies through design innovation
            </h2>
            <p className="fs-5 mb-4 fw-normal lh-2 Transformative-sectionp">
              A full-service creative agency designing and building inventive
              digital experiences across all platforms and brand touchpoints.
            </p>
          </div>
        </div>
      </div>
      <div className="carousel-container">
        <Slider {...settings}>
          <div className="carousel-item">
            <div className="banner-blog-card">
              <img
                src="/img/home/corporate-intranet.png"
                className="card-img-top"
                alt="Blog Banner"
              />
              <div className="row">
              <div className="col-lg-8">
              <div className="blog-detail-box py-4">
                <h3>Corporate Intranet</h3>
                <p>
                  A user-friendly intranet platform simplifying content
                  management and enhancing collaboration across 40 locations,
                  supporting English and Hindi.
                </p>

                <ul className="d-flex ps-3 ">
                  <li>
                    <span>NTPC Ltd</span>
                  </li>
                  <li className="ms-4">
                    <span>Web Portal</span>
                  </li>
                </ul>
              </div>
              </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="banner-blog-card">
              <img
                src="/img/home/coal-production.png"
                className="card-img-top"
                alt="Blog Banner"
              />
              <div className="row">
              <div className="col-lg-8">
              <div className="blog-detail-box py-4">
                <h3>
                Coal Production Management System
                  </h3>
                <p>
                Revolutionizing coal production monitoring with a real-time dashboard and mobile app, offering rich analytics for operational efficiency and safety.
                </p>

                <ul className="d-flex ps-3 ">
                  <li>
                    <span>
                      Data Analytics & BI
                      </span>
                  </li>
                  <li className="ms-4">
                    <span>
                    ASP.NET
                    </span>
                  </li>
                  <li
                    className="ms-4"  >
                    <span>
                    Chart.JS 
                    </span>
                    </li>
                </ul>
              </div>
              </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default TransformCarousel;
