import React, { useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';

const PlantLiveCarousel = () => {
    const carouselRef = useRef(null); // Ref for accessing the carousel instance

    const options = {
        loop: true,
        margin: 10,
        nav: false, // Disable built-in navigation
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.prev();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };
    return (
        <div className="inner-carusel-container">
            <OwlCarousel
                ref={carouselRef}
                className="owl-theme"
                {...options}
            >
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                                <h3 className='industries-retailercardheading'>AI Knowledge Assistant</h3>
                                <h4><b>Client: </b>Large Energy Company, India</h4>
                                <p>
                                A Generative AI-powered document intelligence solution providing instant, context-aware answers and streamlining enterprise knowledge search
                                </p>                               
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                    <img
                            src="/img/industries-retailer/industries-banner-mid.png"
                            alt="Jindal Power Ltd"
                            width="100%"
                            className="fade-in-element left fade-in-left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                            <h3 className='industries-retailercardheading'>Coal Production Management System
                            </h3>
                            <h4><b>Client: </b>NTPC Mining Division</h4>
                                <p>
                                Revolutionizing coal production monitoring with a real-time dashboard and mobile app, offering rich analytics for operational efficiency and safety.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner2.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                            <h3 className='industries-retailercardheading'>Corporate Intranet
                            </h3>
                            <h4><b>Client: </b> NTPC</h4>
                                <p>
                                A user-friendly intranet platform simplifying content management and enhancing collaboration across 40 locations, supporting English and Hindi.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                            <h3 className='industries-retailercardheading'>India Investment Dashboard

                            </h3>
                            <h4><b>Client: </b> Exim Bank of India
                            </h4>
                                <p>
                                An interactive dashboard delivering real-time updates on India’s global investments, driving informed decision-making for policymakers.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                            <h3 className='industries-retailercardheading'>Field Service Management System
                            </h3>
                            <h4><b>Client: </b>  Engineering Company, USA
                            </h4>
                                <p>
                                Mobile-enabled workforce management system ensuring efficient task allocation, real-time tracking, and improved operational clarity.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="case-study-img">
                        <img
                            src="/img/solution/sub-banner.png"
                            alt=""
                            width="100%"
                            className="fade-in-element left"
                        />
                        <div className="case-study-content fade-in-element right">
                            <div className="content-casestudy">
                            <h3 className='industries-retailercardheading'>Drone-Assisted Coal Stock Measurement

                            </h3>
                            <h4><b>Client: </b> NTPC</h4>
                                <p>
                                Leveraging 3D imaging for fast, accurate, and safe coal stockpile volume calculations, enhancing operational accuracy at NTPC Barh.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
            {/* Custom navigation buttons */}
            <button className="custom-prev-button" onClick={handlePrevClick}>
                <i className="fa-solid fa-arrow-left-long"></i>
            </button>
            <button className="custom-next-button" onClick={handleNextClick}>
                <i className="fa-solid fa-arrow-right-long"></i>
            </button>
        </div>
    );
};

export default PlantLiveCarousel;
