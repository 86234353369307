import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SolutionsPlantLive from "./pages/SolutionsPlantLive";
import SolutionsEpsylon from "./pages/SolutionsEpsylon";
import SolutionsLumyn from "./pages/SolutionsLumyn";
import SolutionsUniteam from "./pages/SolutionsUniteam";

import ServicesDataAnalytics from "./pages/ServicesDataAnalytics";
import ServicesERPWorkflow from "./pages/ServicesERPWorkflow";
import ServicesWebPortals from "./pages/ServicesWebPortals";
import ServicesAppModernization from "./pages/ServicesAppModernization";
import ServicesMobileApps from "./pages/ServicesMobileApps";
import ServicesIOTSolutions from "./pages/ServicesIOTSolutions";

import Careers from "./pages/Careers";
import CareersOpening from "./pages/CareersOpening";
import Company from "./pages/Company";

import Insight from "./pages/Insight";
import Contact from "./pages/Contact";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import Blog5 from "./pages/Blog5";
import Blog6 from "./pages/Blog6";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PaasModels from "./pages/Blogs/PaasModels";

import "sweetalert2/dist/sweetalert2.css";
import AiEmissionsReduction from "./pages/Blogs/AiEmissionsReduction";
import EaasEnergy from "./pages/Blogs/EaasEnergy";
import EngineeringJobs from "./pages/Blogs/EngineeringJobs";
import AiDigitalTech from "./pages/Blogs/AiDigitalTech";
import RevolutionisingIndustrialDecarbonisation from "./pages/Blogs/RevolutionisingIndustrialDecarbonisation";
import HarnessingPredictive from "./pages/Blogs/HarnessingPredictive";
import AIHallucinations from "./pages/Blogs/AIHallucinations";
import SmallerModels from "./pages/Blogs/SmallerModels";
import GenerativeAiIndia from "./pages/Blogs/GenerativeAiIndia";
import Industry5Rev from "./pages/Blogs/Industry5Rev";
import MultimodalAi from "./pages/Blogs/MultimodalAi";
import RobotsStealJobs from "./pages/Blogs/RobotsStealJobs";
import HackerVsAi from "./pages/Blogs/HackerVsAi";
import Demystifying from "./pages/Blogs/Demystifying";
import UpskillingWorkforce from "./pages/Blogs/UpskillingWorkforce";
import DemocratizingAi from "./pages/Blogs/DemocratizingAi";
import EconomicPotential from "./pages/Blogs/EconomicPotential";
import GenerativeAiIndustries from "./pages/Blogs/GenerativeAiIndustries";
import DataAnalytics from "./pages/Blogs/DataAnalytics";
import TraditionalMachine from "./pages/Blogs/TraditionalMachine";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/solutions-plant-live"
            element={<SolutionsPlantLive />}
          />
          <Route path="/solutions-epsylon" element={<SolutionsEpsylon />} />
          <Route path="/solutions-lumyn" element={<SolutionsLumyn />} />
          <Route path="/solutions-uniteam" element={<SolutionsUniteam />} />
          <Route
            path="/services-data-analytics"
            element={<ServicesDataAnalytics />}
          />
          <Route
            path="/services-erp-workflow-automation"
            element={<ServicesERPWorkflow />}
          />
          <Route
            path="/services-web-intranet-cms"
            element={<ServicesWebPortals />}
          />
          <Route
            path="/services-mobile-apps"
            element={<ServicesMobileApps />}
          />
          <Route
            path="/services-app-modernization-migration"
            element={<ServicesAppModernization />}
          />
          <Route
            path="/services-iot-solutions"
            element={<ServicesIOTSolutions />}
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/careers-opening" element={<CareersOpening />} />
          <Route
            path="/careers-opening?jobid=id"
            element={<CareersOpening />}
          />
          <Route path="/company" element={<Company />} />
          <Route path="/insight" element={<Insight />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/blog4" element={<Blog4 />} />
          <Route path="/blog5" element={<Blog5 />} />
          <Route path="/blog6" element={<Blog6 />} />
          <Route
            path="/blog/product-as-a-service-models"
            element={<PaasModels />}
          />
          <Route
            path="/blog/ai-for-emissions-reduction-and-environmental-compliance-in-oil-and-gas"
            element={<AiEmissionsReduction />}
          />
          <Route
            path="/blog/energy-as-a-service-eaas-a-new-era-in-sustainable-energy"
            element={<EaasEnergy />}
          />
          {/* AI's Impact on Engineering Jobs: Why Critical Thinking is the Key to Future Success */}
          <Route
            path="/blog/ai-impact-on-engineering-jobs"
            element={<EngineeringJobs />}
          />
          <Route
            path="/blog/ai-and-digital-technologies-powering-the-transition-from-coal"
            element={<AiDigitalTech />}
          />
          <Route
            path="/blog/revolutionising-industrial-decarbonisation-thermal-batteries-net-zero-future"
            element={<RevolutionisingIndustrialDecarbonisation />}
          />
          <Route
            path="/blog/harnessing-predictive-maintenance-and-deep-learning-for-the-energy-sector"
            element={<HarnessingPredictive />}
          />
          <Route
            path="/blog/understanding-ai-hallucinations"
            element={<AIHallucinations />}
          />
          <Route
            path="/blog/smaller-models-bigger-impact"
            element={<SmallerModels />}
          />
          <Route
            path="/blog/generative-ai-in-india"
            element={<GenerativeAiIndia />}
          />
          <Route
            path="/blog/industry-5-revolutionizing-business-strategy"
            element={<Industry5Rev />}
          />
          <Route
            path="/blog/multimodal-ai-the-new-frontier-in-digital-transformation"
            element={<MultimodalAi />}
          />
          <Route
            path="/blog/robots-dont-steal-jobs-they-amplify-them"
            element={<RobotsStealJobs />}
          />
          <Route
            path="/blog/hackers-vs-ai-revolutionizing-cybersecurity"
            element={<HackerVsAi />}
          />
          <Route
            path="/blog/demystifying-the-black-box-why-explainable-ai-is-key-to-successful-digital-transformation"
            element={<Demystifying />}
          />
          <Route
            path="/blog/upskilling-your-workforce-for-the-future"
            element={<UpskillingWorkforce />}
          />
          <Route
            path="/blog/democratizing-ai-how-smes-in-india-can-harness-ais-power-without-breaking-the-bank"
            element={<DemocratizingAi />}
          />
          <Route
            path="/blog/the-economic-potential-of-generative-ai"
            element={<EconomicPotential />}
          />
          <Route
            path="/blog/real-world-applications-of-generative-ai-across-various-industries"
            element={<GenerativeAiIndustries />}
          />
          <Route
            path="/blog/how-generative-ai-improves-data-analytics"
            element={<DataAnalytics />}
          />
            {/* Generative AI vs. Traditional Machine Learning: What's the Difference? */}
          <Route 
            path="/blog/generative-ai-vs-traditional-machine-learning"
            element={<TraditionalMachine />} />


          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
