import React, { useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { AddNewsLetterEmail } from "../service/NewsLetterEmailService";
import Swal from "sweetalert2";
import GeneralUtils from "../utils/GeneralUtils";
import Loader from "../component/Loader";

const Insight = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return;
    } else if (!GeneralUtils.validationForEmail(email)) {
      setError(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await AddNewsLetterEmail({ email: email });
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully subscribed to our blogs",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        setError(false);
      }
      setEmail("");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        text: error?.response?.data?.message || "Something Went Wrong",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header activelink="insights" />

      {isLoading && <Loader />}

      <main className="pb-lg-1 pb-sm-1">
        {/* <!--Page Header--> */}
        <section
          style={{
            background:
              "linear-gradient(110deg, #000 -12.66%, rgba(25, 24, 23, 0.98) 109.42%)",
          }}
        >
          <div className="container-fluid">
            <div className="row ps-md-5">
              <div className="col-lg-8 ps-md-5">
                <div className="bg-image-and-text ps-lg-5 pt-lg-5 fade-in-element left">
                  <h1 className="insightbannertext ">
                    Product as a Service
                    <br /> (PaaS) Models: <br />
                    Transforming the
                    <br /> Manufacturing Landscape
                  </h1>
                  <Link
                    to="/blog/product-as-a-service-models"
                    className="ctabtn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 bg-gray-insight">
                <div className="signup-insight">
                  <p>Want our blogs delivered to your inbox? </p>
                  <h4>Sign up</h4>
                  <div className="d-flex justify-content-between  align-items-center">
                    <input
                      type="email"
                      className="form-control extracss"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Business Email"
                    />
                    <button
                      className="btn bg-dark rounded-circle"
                      onClick={handleSubmit}
                    >
                      <i className="fa-solid fa-arrow-right-long text-white"></i>
                    </button>
                  </div>

                  <hr />
                  <div className="errorbox">
                    {!email && error && (
                      <p
                        style={{ color: "red", fontSize: "14px", margin: "0" }}
                      >
                        **Email is Required
                      </p>
                    )}
                    {email &&
                      !GeneralUtils.validationForEmail(email) &&
                      error && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "14px",
                            margin: "0",
                          }}
                        >
                          **Invalid Email
                        </p>
                      )}
                  </div>
                </div>

                <div className="padding-insight fade-in-element right">
                  <Link
                    to="/blog/ai-for-emissions-reduction-and-environmental-compliance-in-oil-and-gas"
                    className="bloglink"
                  >
                    <div>
                      <p className="text-aiinsight">
                        AI for Emissions Reduction and Environmental Compliance
                        in Oil and Gas
                      </p>
                      <span className="small-textjaisa">
                       November 13, 2024 | 4 min read
                      </span>
                    </div>
                  </Link>

                  <Link to="/blog/energy-as-a-service-eaas-a-new-era-in-sustainable-energy" className="bloglink">
                    <div className="mt-lg-5">
                      <p className="text-aiinsight">
                      Energy-as-a-Service (EaaS): A New Era in
                      Sustainable Energy
                      </p>
                      <span className="small-textjaisa">
                      October 28, 2024 | 3 min read
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Blogs--> */}
        <section className="my-md-5 my-3 pt-md-5">
          <div className="container px-lg-4">
          <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/ai-impact-on-engineering-jobs">
                    <img src="/img/insight/digitalthumb.png" alt="" width="100%" />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link
                    to="/blog/ai-impact-on-engineering-jobs"
                    className="bloglink"
                  >
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>October 11, 2024 | 4 min read</p>
                      <h3>
                      AI's Impact on Engineering Jobs: Why Critical Thinking is the Key to Future Success
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/ai-and-digital-technologies-powering-the-transition-from-coal">
                    <img src="/img/insight/digital.png" alt="" width="100%" />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link
                    to="/blog/ai-and-digital-technologies-powering-the-transition-from-coal"
                    className="bloglink"
                  >
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>October 27, 2024 | 4 min read</p>
                      <h3>
                        AI and Digital Technologies Powering the Transition from
                        Coal
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/revolutionising-industrial-decarbonisation-thermal-batteries-net-zero-future">
                    <img
                      src="/img/insight/Revolutionising.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/revolutionising-industrial-decarbonisation-thermal-batteries-net-zero-future" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>September 12, 2024 | 4 min read</p>
                      <h3>
                      Revolutionising Industrial Decarbonisation: How Thermal Batteries Are Paving the Way for a Net-Zero Future
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/harnessing-predictive-maintenance-and-deep-learning-for-the-energy-sector">
                    <img
                      src="/img/insight/harnessingthubnail.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/harnessing-predictive-maintenance-and-deep-learning-for-the-energy-sector" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>August 23, 2024 | 4 min read</p>
                      <h3>
                      Harnessing Predictive Maintenance and Deep Learning for the Energy Sector
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/understanding-ai-hallucinations">
                    <img
                      src="/img/insight/Hallucinationsthumbnail.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/understanding-ai-hallucinations" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>August 6, 2024 | 4 min read</p>
                      <h3>
                      Understanding AI Hallucinations: Causes, Implications, and Prevention
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/smaller-models-bigger-impact">
                    <img
                      src="/img/insight/smthumbnail.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/smaller-models-bigger-impact" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>June 20, 2024 | 4 min read</p>
                      <h3>
                      Smaller Models, Bigger Impact: The Rise of Efficient AI
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/generative-ai-in-india">
                    <img
                      src="/img/insight/Generative.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/generative-ai-in-india" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>May 29, 2024 | 4 min read</p>
                      <h3>
                      Is Generative AI Delivering on Its Promise in India?
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/industry-5-revolutionizing-business-strategy">
                    <img
                      src="/img/insight/industrey5.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/industry-5-revolutionizing-business-strategy" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>May 18, 2024 | 4 min read</p>
                      <h3>
                      Industry 5.0: Revolutionizing Business Strategy for a Human-Centric, Resilient, and Sustainable Future
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/multimodal-ai-the-new-frontier-in-digital-transformation">
                    <img
                      src="/img/insight/multimodal.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/multimodal-ai-the-new-frontier-in-digital-transformation" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>May 10, 2024 | 4 min read</p>
                      <h3>
                      Multimodal AI: The New Frontier in Digital Transformation
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/robots-dont-steal-jobs-they-amplify-them">
                    <img
                      src="/img/insight/robots.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/robots-dont-steal-jobs-they-amplify-them" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>April 26, 2024 | 4 min read</p>
                      <h3>
                      Robots Don't Steal Jobs, They Amplify Them: How AI Automation Can Supercharge Your Business
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/hackers-vs-ai-revolutionizing-cybersecurity">
                    <img
                      src="/img/insight/hacker.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/hackers-vs-ai-revolutionizing-cybersecurity" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>April 22, 2024 | 4 min read</p>
                      <h3>
                      Hackers vs. AI: How Artificial Intelligence is Revolutionizing Cybersecurity
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/demystifying-the-black-box-why-explainable-ai-is-key-to-successful-digital-transformation">
                    <img
                      src="/img/insight/demystifying.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/demystifying-the-black-box-why-explainable-ai-is-key-to-successful-digital-transformation" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>April 19, 2024 | 4 min read</p>
                      <h3>
                      Demystifying the Black Box: Why Explainable AI is Key to Successful Digital Transformation
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/upskilling-your-workforce-for-the-future">
                    <img
                      src="/img/insight/upskillingthumbnail.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/upskilling-your-workforce-for-the-future" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>April 17, 2024 | 4 min read</p>
                      <h3>
                      Upskilling Your Workforce for the Future: How AI Can Become Your Ultimate L&D Partner
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/democratizing-ai-how-smes-in-india-can-harness-ais-power-without-breaking-the-bank">
                    <img
                      src="/img/insight/Democratizing.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/democratizing-ai-how-smes-in-india-can-harness-ais-power-without-breaking-the-bank" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>March 29, 2024 | 4 min read</p>
                      <h3>
                      Democratizing AI: How SMEs in India Can Harness AI's Power Without Breaking the Bank
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/the-economic-potential-of-generative-ai">
                    <img
                      src="/img/insight/potentialthumbnail.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/the-economic-potential-of-generative-ai" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>July 19, 2024 | 4 min read</p>
                      <h3>
                      The Economic Potential of Generative AI: A Catalyst for Growth
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/real-world-applications-of-generative-ai-across-various-industries">
                    <img
                      src="/img/insight/realWorld.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/real-world-applications-of-generative-ai-across-various-industries" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>June 1, 2023 | 4 min read</p>
                      <h3>
                      Real-World Applications of Generative AI Across Various Industries
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/how-generative-ai-improves-data-analytics">
                    <img
                      src="/img/insight/decisionmaking.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/how-generative-ai-improves-data-analytics" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>May 18, 2023 | 4 min read</p>
                      <h3>
                      How Generative AI Can Improve Data Analytics and Decision-Making
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog/generative-ai-vs-traditional-machine-learning">
                    <img
                      src="/img/insight/MachineLearning.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog/generative-ai-vs-traditional-machine-learning" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>May 14, 2023 | 4 min read</p>
                      <h3>
                      Generative AI vs. Traditional Machine Learning: What's the Difference?
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            
            


            <div className="col-lg-11 mx-auto border-bottom pb-5 mb-4">
              <div className="row justify-content-center ">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog3">
                    <img
                      src="/img/insight/insightcar1.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog3" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>October 16, 2023 | 4 min read</p>
                      <h3>How to Implement Generative AI in Your Enterprise</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11  mx-auto border-bottom pb-5 mt-lg-5 mb-4">
              <div className="row justify-content-center">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog4">
                    <img
                      src="/img/insight/insightcard2.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog4" className="bloglink">
                    <div className="insight-text-card py-lg-5 px-md-4">
                      <p>September 12, 2023 | 3 min read</p>
                      <h3>
                        Navigating the Ethical Frontier of AI: Balancing
                        Progress with Responsibility
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-11 mx-auto pb-5 mt-lg-5 border-bottom mb-4">
              <div className="row justify-content-center pb-md-5">
                <div className="col-lg-5 fade-in-element left mb-4">
                  <Link to="/blog5">
                    <img
                      src="/img/insight/insightcard3.png"
                      alt=""
                      width="100%"
                    />
                  </Link>
                </div>
                <div className="col-lg-5 fade-in-element right">
                  <Link to="/blog5" className="bloglink">
                    <div className="insight-text-card py-lg-5  px-md-4">
                      <p>August 18, 2023 | 3 min read</p>
                      <h3>
                        The Rise of Explainable AI: How We're Making AI More
                        Transparent and Trustworthy
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Insight;
